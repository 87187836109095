/**
 * Camp overview Management Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCurrentCampsList: [],
  allCountStepDataList: [],
  viewSingleBeneficiaryDetail: false,
  allCountCounterDataList: {},
  allCounterDataList: [],
  allBeneficiaryListOfCamp: [],
  allBeneficiaryListOfStepOfCamp: [],
  allBeneficiaryListOfCounterOfStep: [],
  activeCurrentCamp: {
    campIndex: null,
    campName: "--",
    campId: "",
    navStatus: "",
    isActiveCurrentCamp: false,
    searchBeneficiaryCamp: "",
  },
  activeCampCounter: {
    campCounterIndex: null,
    campCounterName: "",
    campCounterId: "",
    isActiveCampCounter: false,
    searchBeneficiaryCounter: "",
  },
  activeCampStep: {
    campStepIndex: null,
    campStepName: "",
    campStepId: "",
    isActiveCampStep: false,
    searchBeneficiaryStep: "",
  },
  loaderBeneficiaryCamp: false,
  loaderBeneficiaryCampStep: false,
  loaderBeneficiaryCampCounter: false,
};

const campOverviewManagementSlice = createSlice({
  name: "campOverviewManagement",
  initialState,
  reducers: {
    setAllCurrentCampsList(state, action) {
      state.allCurrentCampsList = action.payload;
    },
    setActiveCurrentCamp(state, action) {
      state.activeCurrentCamp = {
        ...state.activeCurrentCamp,
        ...action.payload,
      };
    },
    setActiveCampCounter(state, action) {
      state.activeCampCounter = action.payload;
    },
    setActiveCampStep(state, action) {
      state.activeCampStep = {
        ...state.activeCampStep,
        ...action.payload,
      };
    },
    setAllCountCounterDataList(state, action) {
      state.allCountCounterDataList = action.payload;
    },
    setAllCountStepDataList(state, action) {
      state.allCountStepDataList = action.payload;
    },
    setAllBeneficiaryListOfCamp(state, action) {
      state.allBeneficiaryListOfCamp = action.payload;
    },
    setViewSingleBeneficiaryDetail(state, action) {
      state.viewSingleBeneficiaryDetail = action.payload;
    },
    setAllCounterDataList(state, action) {
      state.allCounterDataList = action.payload;
    },
    setAllBeneficiaryListOfStepOfCamp(state, action) {
      state.allBeneficiaryListOfStepOfCamp = action.payload;
    },
    setAllBeneficiaryListOfCounterOfStep(state, action) {
      state.allBeneficiaryListOfCounterOfStep = action.payload;
    },
    setLoaderBeneficiaryCamp(state, action) {
      state.loaderBeneficiaryCamp = action.payload;
    },
    setLoaderBeneficiaryCampStep(state, action) {
      state.loaderBeneficiaryCampStep = action.payload;
    },
    setLoaderBeneficiaryCampCounter(state, action) {
      state.loaderBeneficiaryCampCounter = action.payload;
    },
  },
});

// Reducer
export const campOverviewManagementReducer =
  campOverviewManagementSlice.reducer;

// Actions
export const {
  setAllCurrentCampsList,
  setActiveCurrentCamp,
  setActiveCampCounter,
  setActiveCampStep,
  setAllCountCounterDataList,
  setAllCountStepDataList,
  setAllBeneficiaryListOfCamp,
  setViewSingleBeneficiaryDetail,
  setAllCounterDataList,
  setAllBeneficiaryListOfStepOfCamp,
  setAllBeneficiaryListOfCounterOfStep,
  setLoaderBeneficiaryCamp,
  setLoaderBeneficiaryCampStep,
  setLoaderBeneficiaryCampCounter,
} = campOverviewManagementSlice.actions;

export const allCurrentCamps = createAction("ALLCURRENTCAMPS");
export const allBeneficiaryDetailOfCampByCampId = createAction(
  "ALLBENEFICIARYDETAILOFCAMPBYCAMPID"
);

export const countStepDataOfCurrentCamp = createAction(
  "COUNTSTEPDATAOFCURRENTCAMP"
);

export const countCounterDataOfCurrentCampStep = createAction(
  "COUNTCOUNTERDATAOFCURRENTCAMPSTEP"
);

export const getCounterAndBeneficiaryOfStepOfCurrentCamp = createAction(
  "GETCOUNTERANDBENEFICIARYOFSTEPOFCURRENTCAMP"
);

export const getBeneficiaryOfCounterOfStepOfCurrentCamp = createAction(
  "GETBENEFICIARYOFCOUNTEROFSTEPOFCURRENTCAMP"
);

export const endStartCamp = createAction("ENDSTARTCAMP");
