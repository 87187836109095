/**
 * Custom Button Component
 * @flow
 * @format
 */

import { Button } from "@material-tailwind/react";
import React from "react";

const CustomButton = (props) => {
  const { name, type, className, loading, disabled, onClick, icon, size } =
    props;
  return (
    <>
      <Button
        loading={loading}
        className={`text-white text-[18px] px-4 py-2 rounded-md font-bold bg-WildWatermelon-button h-full capitalize cursor-pointer ${className}`}
        type={type}
        disabled={disabled}
        onClick={onClick}
        size={size}
      >
        {icon}
        {name}
      </Button>
    </>
  );
};

export default CustomButton;
