import React from "react";

const ViewMapLink = ({ geo_coordinates }) => {
  // Construct the Google Maps URL with latitude and longitude
  const mapUrl = `https://www.google.com/maps?q=${geo_coordinates}`;

  return (
    <div>
      <a
        href={mapUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#256CD0] font-semibold underline"
      >
        View Map
      </a>
    </div>
  );
};

export default ViewMapLink;
