import React from "react";

export const PrintIcon = ({ width, height, iconColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25098 7H16.751V5C16.751 3 16.001 2 13.751 2H10.251C8.00098 2 7.25098 3 7.25098 5V7Z"
        stroke="#FF5976"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.001 15V19C16.001 21 15.001 22 13.001 22H11.001C9.00098 22 8.00098 21 8.00098 19V15H16.001Z"
        stroke="#FF5976"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 10V15C21 17 20 18 18 18H16V15H8V18H6C4 18 3 17 3 15V10C3 8 4 7 6 7H18C20 7 21 8 21 10Z"
        stroke="#FF5976"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.999 15H15.789H6.99902"
        stroke="#FF5976"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.99902 11H9.99902"
        stroke="#FF5976"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
