/**
 * Camp Center Management Selector
 * @format
 */

const campCenterManagement = (state) => state.campCenterManagementReducer;

export const selectAllCampCenterList = (state) =>
  campCenterManagement(state).allCampCentersList;

export const selectCampCenter = (state) =>
  campCenterManagement(state).campCenter;

export const selectCampCenterCurrentPage = (state) =>
  campCenterManagement(state).CampCenterCurrentPage;

export const selectCampCenterRespPerPage = (state) =>
  campCenterManagement(state).CampCenterRespPerPage;

export const selectAllStateWithCampCenterCount = (state) =>
  campCenterManagement(state).allStateWithCampCenterCount;

export const selectCampCenterStateId = (state) =>
  campCenterManagement(state).CampCenterStateId;

export const selectCampCenterSearchQuery = (state) =>
  campCenterManagement(state).campCenterSearchQuery;
