// /**
//  * Pagination Component
//  * @format
//  */

import React, { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBeneficiaryDetailCurrentPage,
  selectBeneficiaryStatus,
  selectBeneficiaySearch,
  selectCurrCampCurrentPage,
  selectCurrCampDataPerPage,
  selectCurrCampDistrictId,
  selectCurrCampFilter,
  selectCurrCampNavStatus,
  selectCurrCampSearch,
  selectCurrCampStateId,
  selectDistricId,
  selectFilter,
  selectStateId,
} from "../pages/beneficiary_management/selector";
import {
  selectBeneficiaryQueryCurrentPage,
  selectBeneficiaryQueryRespPerPage,
  selectBeneficiaryQuerySearch,
  selectCampUserCounterData,
} from "../pages/camp_management/selector";
import {
  setBeneficiaryDetailDataPerPage,
  setCurrCampDataPerPage,
} from "../pages/beneficiary_management/slice";
import { setbeneficiaryQueryRespPerPage } from "../pages/camp_management/slice";
import {
  selectFabricationBeneficiaryListCampDistrictId,
  selectFabricationBeneficiaryListCampFilter,
  selectFabricationBeneficiaryListCampStateId,
  selectFabricationBeneficiaryListCurrentPage,
  selectFabricationBeneficiaryListDataPerPage,
  selectFabricationBeneficiaryListNavStatus,
  selectFabricationBeneficiaryListSearchQuery,
  selectFabricationNavStatus,
  selectFabricationSearchQuery,
} from "../pages/fabrication_management/selector";
import {
  setFabricationBeneficiaryListDataPerPage,
  setFabricationDataPerPage,
} from "../pages/fabrication_management/slice";

export default function Pagination(props) {
  const {
    name,
    totalNumber,
    dataPerPage,
    data,
    currentPageApi,
    perPageOptions,
    showFirstButton,
    showLastButton,
  } = props;

  const [page, setPage] = useState(currentPageApi - 1 || 0);
  const [rowsPerPage, setRowsPerPage] = useState(dataPerPage);
  const dispatch = useDispatch();
  const districtId = useSelector(selectDistricId);
  const stateId = useSelector(selectStateId);
  const statusBeneficiary = useSelector(selectBeneficiaryStatus);
  const currentPage = useSelector(selectBeneficiaryDetailCurrentPage);
  const filter = useSelector(selectFilter);

  //camp
  const campSearch = useSelector(selectCurrCampSearch);
  const campCurrentPage = useSelector(selectCurrCampCurrentPage);
  const campLimit = useSelector(selectCurrCampDataPerPage);
  const campDistrictId = useSelector(selectCurrCampDistrictId);
  const campStateId = useSelector(selectCurrCampStateId);
  const campFilter = useSelector(selectCurrCampFilter);
  const campNavStatus = useSelector(selectCurrCampNavStatus);
  const userCountersData = useSelector(selectCampUserCounterData) || {};
  const searchQueryVerificationScreen =
    useSelector(selectBeneficiaySearch) || "";

  //query beneficiary list
  const querySearchQuery = useSelector(selectBeneficiaryQuerySearch);
  const queryCurrentPage = useSelector(selectBeneficiaryQueryCurrentPage);
  const queryRespPerPage = useSelector(selectBeneficiaryQueryRespPerPage);

  // fabricationCampBeneficiaryListScreen
  const fabricationBeneficiarySearch = useSelector(
    selectFabricationBeneficiaryListSearchQuery
  );
  const fabricationBeneficiaryCurrentPage = useSelector(
    selectFabricationBeneficiaryListCurrentPage
  );
  const fabricationBeneficiaryLimit = useSelector(
    selectFabricationBeneficiaryListDataPerPage
  );
  const fabricationBeneficiaryCampNavStatusName = useSelector(
    selectFabricationBeneficiaryListNavStatus
  );
  const fabricationBeneficiaryStateId = useSelector(
    selectFabricationBeneficiaryListCampStateId
  );
  const fabricationBeneficiaryFilter = useSelector(
    selectFabricationBeneficiaryListCampFilter
  );
  const fabricationBeneficiaryDistrictId = useSelector(
    selectFabricationBeneficiaryListCampDistrictId
  );

  // fabrication camps list
  const fabricationSearch = useSelector(selectFabricationSearchQuery) || "";
  const fabricationNavStatus = useSelector(selectFabricationNavStatus);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (name === "runningCampScreen") {
      data(
        campSearch,
        campStateId,
        campDistrictId,
        campNavStatus,
        newPage + 1,
        rowsPerPage,
        campFilter,
        userCountersData?.step
      );
    } else if (name === "beneficiaryVerficationScreen") {
      data(
        searchQueryVerificationScreen,
        stateId,
        districtId,
        statusBeneficiary,
        newPage + 1,
        rowsPerPage,
        filter
      );
    } else if (name === "queryBeneficiary") {
      data(querySearchQuery, newPage + 1, rowsPerPage);
    } else if (name === "fabricationCampBeneficiaryListScreen") {
      data(
        fabricationBeneficiarySearch,
        fabricationBeneficiaryStateId,
        fabricationBeneficiaryDistrictId,
        fabricationBeneficiaryCampNavStatusName,
        newPage + 1,
        rowsPerPage,
        fabricationBeneficiaryFilter
      );
    } else if (name === "fabricationListScreen") {
      data(fabricationSearch, fabricationNavStatus);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (name === "runningCampScreen") {
      dispatch(setCurrCampDataPerPage(newRowsPerPage));
    } else if (name === "beneficiaryVerficationScreen") {
      dispatch(setBeneficiaryDetailDataPerPage(newRowsPerPage));
    } else if (name === "queryBeneficiary") {
      dispatch(setbeneficiaryQueryRespPerPage(newRowsPerPage));
    } else if (name === "fabricationCampBeneficiaryListScreen") {
      dispatch(setFabricationBeneficiaryListDataPerPage(newRowsPerPage));
    } else if (name === "fabricationListScreen") {
      dispatch(setFabricationDataPerPage(newRowsPerPage));
    }
  };

  useEffect(() => {
    setRowsPerPage(dataPerPage);
  }, [dataPerPage]);

  return (
    <TablePagination
      component="div"
      count={totalNumber || 0}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage || 0}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={perPageOptions}
      showFirstButton={showFirstButton}
      showLastButton={showLastButton}
    />
  );
}
