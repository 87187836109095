/**
 * Routing Module
 * @format
 */

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

// Screens
import { Loader, ToastComponent } from "../components";
import {
  AuthRoutes,
  BeneficiaryRoutes,
  CampCentersRoutes,
  CampOverviewRoutes,
  CampsRoutes,
  FabricationRoutes,
  MainRoutes,
  RoleRoutes,
  UserRoutes,
} from "../constants";
import { AuthChecker } from "./authRouters";
import { LoginScreen } from "../pages/auth";
import { DashboardScreen } from "../pages/dashboard";
import Custom404 from "../components/404";
import { selectAuthToken, selectUserAllPermissions } from "../pages/common";
import { BeneficiaryVerificationsScreen } from "../pages/beneficiary_management";
import { UserListScreen } from "../pages/user_management";
import { RoleListScreen } from "../pages/role_management";
import { ListCampModel } from "../pages/camp_management/view/campListScreen";
import { ListCampCenterModel } from "../pages/camp_center_management/view/campCenterListScreen";
import { CampOverviewScreen } from "../pages/camp_overview_management/view/campOverviewScreen";
import { RunningCampScreen } from "../pages/beneficiary_management/view/runningCampScreen";
import { MyCampsDashboardScreen } from "../pages/camp_management/view/myCampsDashboardScreen";
import { FabricationListScreen } from "../pages/fabrication_management/view/fabricationListScreen";
import { FabricationCampBeneficiayListScreen } from "../pages/fabrication_management/view/faricationCampBeneficiaryListScreen";

export default function AppRouter() {
  const token = useSelector(selectAuthToken);
  const userPermissions = useSelector(selectUserAllPermissions) || {};

  return (
    <div className="App h-screen overflow-y-auto scrollbar-hide bg-customBlue-mainBackground">
      <Router>
        <ToastComponent />
        <Routes>
          <Route
            path="/"
            element={
              token ? (
                userPermissions?.View_Dashboard === true ? (
                  <Navigate to="/dashboard" replace />
                ) : userPermissions?.View_Dashboard === false ? (
                  <Navigate to="/myCampList" replace />
                ) : (
                  <div className="border-2 border-red-500">
                    <Loader />
                  </div>
                )
              ) : (
                <Navigate to={AuthRoutes.LoginScreen} replace />
              )
            }
          />
          {/* Protected Routes */}
          <Route path="/" element={<AuthChecker token={token} />}>
            {userPermissions?.View_Dashboard === true && (
              <Route
                key="dashboard"
                path={MainRoutes.DashboardScreen}
                element={<DashboardScreen />}
              />
            )}
            {/* Beneficiary Management */}
            {userPermissions?.View_Beneficiary_List === true && (
              <>
                <Route
                  key="beneficiary-verification"
                  path={BeneficiaryRoutes.BeneficiaryVerification}
                  element={
                    <BeneficiaryVerificationsScreen name="Verification" />
                  }
                />
                <Route
                  key="beneficiary-assessment"
                  path={BeneficiaryRoutes.BeneficiaryAssessment}
                  element={
                    <BeneficiaryVerificationsScreen name="Assessments" />
                  }
                />
                <Route
                  key="beneficiary-fabrication"
                  path={BeneficiaryRoutes.BeneficiaryFabrication}
                  element={
                    <BeneficiaryVerificationsScreen name="Fabrication" />
                  }
                />
                <Route
                  key="beneficiary-distribution"
                  path={BeneficiaryRoutes.BeneficiaryDistribution}
                  element={
                    <BeneficiaryVerificationsScreen name="Distribution" />
                  }
                />
              </>
            )}
            {/* User Management */}
            {userPermissions?.View_UserList === true && (
              <Route
                key="user-list"
                path={UserRoutes.UserList}
                element={<UserListScreen />}
              />
            )}

            {/* Role Management */}
            {userPermissions?.View_Roles_List === true && (
              <Route
                key="role-list"
                path={RoleRoutes.RoleList}
                element={<RoleListScreen />}
              />
            )}

            {/* Camp Management */}
            {userPermissions?.View_Scheduled_Camps === true && (
              <Route
                key="camp-list"
                path={CampsRoutes.CampsList}
                element={<ListCampModel />}
              />
            )}
            <Route
              key="running-camp"
              path={CampsRoutes.MyCamp}
              element={<RunningCampScreen />}
            />
            <Route
              key="my-camp-list"
              path={CampsRoutes.MyCampList}
              element={<MyCampsDashboardScreen />}
            />

            {/* Camp Center Management */}
            {userPermissions?.View_Camp_Centres === true && (
              <Route
                key="camp-center-list"
                path={CampCentersRoutes.CampCentersList}
                element={<ListCampCenterModel />}
              />
            )}

            {/* Camp OverView Management */}
            {userPermissions?.View_Camp_Overview === true && (
              <Route
                key="camp-overview-list"
                path={CampOverviewRoutes.CampOverviewList}
                element={<CampOverviewScreen />}
              />
            )}

            {/* Camp Fabrication Management */}
            <Route
              key="fabrication-list-screen"
              path={FabricationRoutes.FabricationListScreen}
              element={<FabricationListScreen />}
            />
            <Route
              key="beneficiary-List-Fabrication"
              path={FabricationRoutes.BeneficiaryListOfFabricationScreen}
              element={<FabricationCampBeneficiayListScreen />}
            />
          </Route>

          {/* Public Route */}
          <Route
            path={AuthRoutes.LoginScreen}
            element={
              token ? (
                userPermissions?.View_Dashboard === true ? (
                  <Navigate to="/dashboard" replace />
                ) : userPermissions?.View_Dashboard === false ? (
                  <Navigate to="/myCampList" replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              ) : (
                <LoginScreen />
              )
            }
          />

          {/* Catch-all Route for 404 - Not Found */}
          <Route path="*" element={<Custom404 />} />
        </Routes>
      </Router>
    </div>
  );
}
