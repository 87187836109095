/**
 * Assessment Components
 * @format
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { getFieldBox, manipulateDateAndTime } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  generatePDFOfAssessment,
  getStepAndCounterOfUser,
  updateRequiredDataOfCampSteps,
} from "../../camp_management/slice";
import CustomButton from "../../../components/customButtion";
import {
  beneficiaryFormUpload,
  getUploadedFormByBeneficiaryId,
} from "../slice";
import {
  selectBeneficiaryUploadedForm,
  selectCurrCampCurrentPage,
  selectCurrCampDataPerPage,
  selectCurrCampDistrictId,
  selectCurrCampFilter,
  selectCurrCampNavStatus,
  selectCurrCampSearch,
  selectCurrCampStateId,
  selectHtmlOfPdf,
} from "../selector";
import { NoRecord } from "../../../components/noRecords";
import { toast } from "react-toastify";
import { PrintIcon } from "../../../assets/icons/PrintIcon";
import AssessmentPdf from "../../../components/assessmentPdf";
import { useRef } from "react";

export function AssessmentComponent(props) {
  const dispatch = useDispatch();
  const {
    campSteps,
    campData,
    beneficiary_id,
    scheduled_camp_id,
    status,
    componentName,
    getJourneyData,
    getBeneficiaryListCallback,
  } = props;
  const [userCountersData, setUserCountersData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewHtml, setViewHtml] = useState(false);

  // State to hold a modifiable copy of campSteps
  const [localCampSteps, setLocalCampSteps] = useState([]);
  const beneficiayFileData = useSelector(selectBeneficiaryUploadedForm);
  const { assessmentHtmlTemplate, distributionHtmlTemplate } =
    useSelector(selectHtmlOfPdf);
  // running camp
  const runningCampCurrentPage = useSelector(selectCurrCampCurrentPage);
  const runningCampSearch = useSelector(selectCurrCampSearch);
  const runningCampLimit = useSelector(selectCurrCampDataPerPage);
  const runningCampDistrictId = useSelector(selectCurrCampDistrictId);
  const runningCampStateId = useSelector(selectCurrCampStateId);
  const runningCampFilter = useSelector(selectCurrCampFilter);
  const runningCampNavStatus = useSelector(selectCurrCampNavStatus);
  // const userCountersData = useSelector(selectCampUserCounterData) || {};

  // Process and clone campSteps on initial load

  useEffect(() => {
    const counterData = campData?.[0]?.camp_flow?.filter(
      (item) => item?.step_status === "completed"
    );

    const clonedCampSteps = campSteps?.map((step) => {
      const matchingCounter = counterData?.find(
        (counter) =>
          counter?.camp_step_id?._id === step?.camp_step_id?.camp_step_id?._id
      );

      return {
        ...step,
        data: matchingCounter ? matchingCounter?.data : null,
      };
    });

    setLocalCampSteps(clonedCampSteps);
    if (campData?.[0]?.scheduled_camp_id?._id) {
      getUserCounterData(campData?.[0]?.scheduled_camp_id?._id);
    }
  }, [campSteps, campData]);

  const [selected, setSelected] = useState(null);
  const [openStepBody, setOpenStepBody] = useState(null);
  const [initialObject, setInitialObject] = useState({});
  const fileInputRef = useRef(null);

  const getUserCounterData = (id) => {
    dispatch(
      getStepAndCounterOfUser({
        scheduled_camp_id: id,
        onSuccess: (props) => {
          setUserCountersData(props?.data);
        },
      })
    );
  };

  const toggleStep = (stepName) => {
    setOpenStepBody((prevStepName) =>
      prevStepName === stepName ? null : stepName
    );
  };

  // // Uploard file
  // const handleUploadButtonClick = (stepId) => {
  //   if (selectedFile) {
  //     dispatch(
  //       beneficiaryFormUpload({
  //         beneficiary_id,
  //         scheduled_camp_id,
  //         camp_step_id: stepId,
  //         beneficiary_form: selectedFile,
  //         onSuccess: (props) => {
  //           toast.success(props?.message);
  //         },
  //       })
  //     );
  //   }
  // };

  // Extract initial values from localCampSteps and set them
  useEffect(() => {
    const initialValues = {};

    localCampSteps?.forEach((stepItem) => {
      stepItem?.camp_step_id?.required_data_id?.forEach((fieldItem) => {
        const fieldName =
          fieldItem?.field_box === "textArea"
            ? `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
            : fieldItem?.field_name;

        initialValues[fieldName] = stepItem?.data?.[fieldName] || "";
      });
    });

    setInitialObject(initialValues);
    setSelected(initialValues?.checked_in_person ?? selected);
  }, [localCampSteps]);

  useEffect(() => {
    if (beneficiary_id) {
      dispatch(
        getUploadedFormByBeneficiaryId({
          beneficiary_id,
          scheduled_camp_id,
          camp_step_id: userCountersData?.stepId,
          onSuccess: () => {},
        })
      );
    }
    if (userCountersData) {
      toggleStep(userCountersData?.step);
    }
  }, [beneficiary_id, userCountersData]);

  function getValidationSchema(field) {
    switch (field.field_box) {
      case "inputText":
        return Yup.string()
          .required(`Required`)
          .max(255, `${field.field_display_name} cannot exceed 255 characters`);

      case "textArea":
        return Yup.string().required(`Required`);

      case "singleSelect":
        return Yup.string().required(`Required`);

      case "multiSelect":
        return Yup.array()
          .of(Yup.string())
          .min(1, `Required at least one option`)
          .required(`Required`);

      case "file":
        return Yup.string()
          .test("file", "Only PDF files are supported", function (value) {
            console.log("File Value:", value);
            return !value || (value && value.type === "application/pdf");
          })
          .optional();

      case "radio":
        return Yup.string().required(`Required`);

      default:
        return Yup.mixed();
    }
  }

  function createValidationSchema(fields, step) {
    const schema = {};

    if (!Array.isArray(fields) || fields.length === 0) {
      console.warn("No fields provided for schema creation");
      return Yup.object().shape(schema);
    }

    fields.forEach((field) => {
      const fieldName =
        field.field_box === "textArea"
          ? `${step?.camp_step_name}_${field?.field_name}`
          : field?.field_name;

      schema[fieldName] = getValidationSchema(field);
    });

    return Yup.object().shape(schema);
  }

  // Get validation schema only for opened step
  const openStepIndex = localCampSteps.findIndex(
    (step) => step.camp_step_id?.camp_step_id?.camp_step_name === openStepBody
  );
  const activeStepFields =
    openStepIndex !== -1
      ? localCampSteps[openStepIndex]?.camp_step_id?.required_data_id || []
      : [];

  const activeStep =
    openStepIndex !== -1
      ? localCampSteps[openStepIndex]?.camp_step_id?.camp_step_id || {}
      : {};
  // Usage in Formik
  const validationSchema = createValidationSchema(activeStepFields, activeStep);

  return (
    <>
      <Formik
        initialValues={initialObject}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, actions) => {
          const { step, ...restValues } = values;

          let statusId = "";

          if (componentName === "distribution") {
            statusId =
              step?.step_type?.camp_step_type_name === "initial" ||
              step?.step_type?.camp_step_type_name === "intermediate"
                ? status?.distribution_in_progress
                : step?.step_type?.camp_step_type_name === "final"
                ? status?.distribution_completed
                : null;
          } else if (componentName === "assessment") {
            statusId =
              step?.step_type?.camp_step_type_name === "initial" ||
              step?.step_type?.camp_step_type_name === "intermediate"
                ? status?.assessment_in_progress
                : step?.step_type?.camp_step_type_name === "final"
                ? status?.assessement_completed
                : null;
          }

          const filteredData = {};
          const fileUploadPromises = step?.camp_step_id?.required_data_id?.map(
            async (fieldItem) => {
              const fieldName =
                fieldItem?.field_box === "textArea"
                  ? `${step?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                  : fieldItem?.field_name;

              if (fieldItem?.field_box === "file" && selectedFile) {
                return new Promise((resolve) => {
                  dispatch(
                    beneficiaryFormUpload({
                      beneficiary_id,
                      scheduled_camp_id,
                      camp_step_id: userCountersData?.stepId,
                      beneficiary_form: selectedFile,
                      onSuccess: (props) => {
                        const { message, data } = props;
                        toast.success(message);
                        filteredData[fieldName] = data || "";
                        resolve();
                      },
                    })
                  );
                });
              } else if (restValues[fieldName]) {
                filteredData[fieldName] = restValues[fieldName];
              }
            }
          );

          try {
            // Wait for all file uploads to complete
            await Promise.all(fileUploadPromises);

            const updateValues = {
              beneficiary_id,
              scheduled_camp_id,
              current_status: statusId,
              camp_flow: {
                camp_step_id: step?.camp_step_id?.camp_step_id?._id,
                camp_counter_id: userCountersData?.counter_id,
                step_status: "completed",
                data: filteredData,
              },
            };

            // Dispatch the update action
            dispatch(
              updateRequiredDataOfCampSteps({
                values: updateValues,
                onSuccess: (props) => {
                  getBeneficiaryListCallback(
                    runningCampSearch,
                    runningCampStateId,
                    runningCampDistrictId,
                    runningCampNavStatus,
                    runningCampCurrentPage,
                    runningCampLimit,
                    runningCampFilter,
                    userCountersData?.step
                  );
                  getJourneyData(beneficiary_id);
                },
              })
            );
          } catch (error) {
            toast.error("An error occurred. Please try again.");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
            encType="multipart/form-data"
          >
            <div className="w-full flex flex-col justify-start items-start space-y-0 divide-y-2">
              {localCampSteps?.map((stepItem, stepIndex) => {
                const isCheckinStep =
                  userCountersData?.step ===
                  stepItem?.camp_step_id?.camp_step_id?.camp_step_name;

                return (
                  <div className="w-full py-3" key={stepIndex}>
                    <label
                      className={`cursor-pointer font-bold text-base ${
                        isCheckinStep ? "" : "text-gray-500"
                      }`}
                      onClick={() =>
                        isCheckinStep && toggleStep(userCountersData?.step)
                      }
                    >
                      {`Step ${stepIndex + 1}: ${
                        stepItem?.camp_step_id?.camp_step_id
                          ?.camp_step_display_name
                      }`}
                    </label>
                    {openStepBody === userCountersData?.step &&
                      isCheckinStep && (
                        <div className="my-2">
                          {campData && campSteps ? (
                            <>
                              {stepItem?.camp_step_id?.required_data_id?.map(
                                (fieldItem, fieldIndex) => {
                                  let props = {};
                                  if (fieldItem?.field_box === "radio") {
                                    props = {
                                      name: fieldItem?.field_name,
                                      id: fieldItem?.field_name,
                                      setFieldValue,
                                      setSelected,
                                      selected,
                                      isVerified: stepItem?.data ? true : false,
                                      campStep:
                                        stepItem?.camp_step_id?.camp_step_id
                                          ?._id,
                                    };
                                  } else if (
                                    fieldItem?.field_box === "textArea"
                                  ) {
                                    props = {
                                      name: `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`,
                                      id: `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`,
                                      onChange: handleChange,
                                      disabled: false,
                                      // disabled:
                                      //   values[
                                      //     `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                                      //   ],
                                      placeholder: "",
                                      value:
                                        values[
                                          `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                                        ],
                                      className: "",
                                    };
                                  } else if (fieldItem?.field_box === "file") {
                                    props = {
                                      name: fieldItem?.field_name,
                                      id: fieldItem?.field_name,
                                      disabled:
                                        beneficiayFileData?.beneficiary_form_key
                                          ? true
                                          : false,
                                      placeholder: "Choose File",
                                      className: "",
                                      handleFileChange: (e) => {
                                        const files = e.target.files[0];
                                        if (files) {
                                          setSelectedFile(files);
                                        }
                                        if (fileInputRef.current) {
                                          fileInputRef.current.value = "";
                                        }
                                      },
                                      value:
                                        beneficiayFileData?.beneficiary_form_name ||
                                        selectedFile?.name ||
                                        "",
                                      // buttonOnClick: handleUploadButtonClick(
                                      //   stepItem?.camp_step_id?.camp_step_id
                                      //     ?._id
                                      // ),
                                      buttonName: "Upload",
                                      buttonDisabled:
                                        beneficiayFileData?.beneficiary_form_key
                                          ? true
                                          : false,
                                      selectedFile,
                                      crossButtonOnckick: () => {
                                        setSelectedFile(null);
                                      },
                                      crossButtonDisabled:
                                        beneficiayFileData?.beneficiary_form_key
                                          ? true
                                          : false,
                                      viewButtonOnckick: () => {
                                        if (
                                          beneficiayFileData?.beneficiary_form
                                        ) {
                                          const fileURL =
                                            beneficiayFileData?.beneficiary_form;
                                          window.open(fileURL, "_blank");
                                        } else if (selectedFile) {
                                          const fileURL =
                                            URL.createObjectURL(selectedFile);
                                          window.open(fileURL, "_blank");
                                        }
                                      },
                                    };
                                  } else if (
                                    fieldItem?.field_box === "inputText"
                                  ) {
                                    props = {
                                      name: fieldItem?.field_name,
                                      id: fieldItem?.field_name,
                                      onChange: handleChange,
                                      type: "text",
                                      placeholder: "",
                                      value: values[fieldItem?.field_name],
                                      className: "",
                                    };
                                  } else if (
                                    fieldItem?.field_box === "singleSelect"
                                  ) {
                                    props = {
                                      name: fieldItem?.field_name,
                                      id: fieldItem?.field_name,
                                      options: fieldItem?.option_id?.map(
                                        (item) => ({
                                          label: item?.state_name,
                                          value: item?._id,
                                        })
                                      ),
                                      value:
                                        fieldItem?.option_id
                                          ?.map((item) => ({
                                            label: item?.state_name,
                                            value: item?._id,
                                          }))
                                          ?.find(
                                            (item) =>
                                              item?.value ===
                                              values[fieldItem?.field_name]
                                          ) || null,
                                      onChange: (selectedOption) => {
                                        const selectedAccess =
                                          selectedOption?.value || "";
                                        setFieldValue(
                                          fieldItem?.field_name,
                                          selectedAccess
                                        );
                                      },
                                      touched: touched,
                                      errors: errors,
                                      disabled: false,
                                      placeholder: "Select",
                                      isSearchable: true,
                                      isClearable: false,
                                    };
                                  } else if (
                                    fieldItem?.field_box === "multiSelect"
                                  ) {
                                    props = {
                                      name: fieldItem?.field_name,
                                      id: fieldItem?.field_name,
                                      displayValue: "label",
                                      showCheckbox: true,
                                      showArrow: true,
                                      placeholder: "Select",
                                      disabled: false,
                                      options: fieldItem?.option_id?.map(
                                        (item) => ({
                                          label: item?.option_display_name,
                                          value: item?._id,
                                        })
                                      ),
                                      onSelect: (selectedList) =>
                                        setFieldValue(
                                          fieldItem?.field_name,
                                          selectedList.map((item) => item.value)
                                        ),
                                      onRemove: (selectedList) =>
                                        setFieldValue(
                                          fieldItem?.field_name,
                                          selectedList.map((item) => item.value)
                                        ),
                                      selectedValues: fieldItem?.option_id
                                        ?.map((item) => ({
                                          label: item?.option_display_name,
                                          value: item?._id,
                                        }))
                                        .filter((option) =>
                                          values[
                                            fieldItem?.field_name
                                          ]?.includes(option.value)
                                        ),
                                    };
                                  }

                                  return (
                                    <>
                                      <div
                                        key={fieldIndex}
                                        className="flex flex-col space-y-2 items-start mb-2 w-full"
                                      >
                                        <div className="w-full text-black font-semibold">
                                          {fieldItem?.field_display_name}
                                        </div>
                                        <div className="w-full">
                                          {getFieldBox(
                                            fieldItem?.field_box,
                                            props
                                          )}
                                          {fieldItem?.field_box === "file" && (
                                            <div className="text-[#8E8E8E] font-medium text-xs mt-1">
                                              Only PDF format in supported
                                            </div>
                                          )}
                                          {(() => {
                                            const fieldName =
                                              fieldItem?.field_box ===
                                              "textArea"
                                                ? `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                                                : fieldItem?.field_name;

                                            return (
                                              errors[fieldName] &&
                                              touched[fieldName] && (
                                                <div className="text-red-500 text-sm mt-1">
                                                  {errors[fieldName]}
                                                </div>
                                              )
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                              <div className="flex flex-row justify-between items-center w-full">
                                <CustomButton
                                  name="Save"
                                  type="submit"
                                  loading={false}
                                  disabled={stepItem?.data}
                                  onClick={() =>
                                    setFieldValue("step", stepItem)
                                  }
                                  className={"text-white mt-2"}
                                />
                                {componentName === "assessment" &&
                                  stepItem?.camp_step_id?.camp_step_id
                                    ?.camp_step_name === "check_in" && (
                                    <div
                                      className="flex flex-row items-center space-x-2 bg-White cursor-pointer"
                                      onClick={() => {
                                        dispatch(
                                          generatePDFOfAssessment({
                                            beneficiary_id,
                                            scheduled_camp_id,
                                            onSuccess: (props) => {
                                              if (props) {
                                                setViewHtml(true);
                                              }
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <span className="">
                                        <PrintIcon height={24} width={24} />
                                      </span>
                                      <span className="text-WildWatermelon-button font-semibold text-lg">
                                        Print Assessment Form
                                      </span>
                                    </div>
                                  )}
                                {componentName === "distribution" &&
                                  stepItem?.camp_step_id?.camp_step_id
                                    ?.camp_step_name === "check_in" && (
                                    <div
                                      className="flex flex-row items-center space-x-2 bg-White cursor-pointer"
                                      onClick={() => {
                                        dispatch(
                                          generatePDFOfAssessment({
                                            beneficiary_id,
                                            scheduled_camp_id,
                                            onSuccess: (props) => {
                                              if (props) {
                                                setViewHtml(true);
                                              }
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <span className="">
                                        <PrintIcon height={24} width={24} />
                                      </span>
                                      <span className="text-WildWatermelon-button font-semibold text-lg">
                                        Print Distribution Form
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex justify-center items-center container pr-4 pt-2 h-fit w-full rounded-lg">
                                <NoRecord
                                  title_1={"No Step Fields"}
                                  title_2={""}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </form>
        )}
      </Formik>
      {viewHtml && (
        <AssessmentPdf
          htmlContent={
            componentName === "assessment"
              ? assessmentHtmlTemplate
              : componentName === "distribution"
              ? distributionHtmlTemplate
              : ""
          }
          cancel={setViewHtml}
        />
      )}
    </>
  );
}

export function AssessmentReadOnlyComponent(props) {
  const { campSteps, campData } = props;

  // State to hold a modifiable copy of campSteps
  const [localCampSteps, setLocalCampSteps] = useState([]);
  const beneficiayFileData = useSelector(selectBeneficiaryUploadedForm);

  const [initialObject, setInitialObject] = useState({});

  // Process and clone campSteps on initial load
  useEffect(() => {
    const counterData = campData?.[0]?.camp_flow?.filter(
      (item) => item?.step_status === "completed"
    );

    const clonedCampSteps = campSteps?.map((step) => {
      const matchingCounter = counterData?.find(
        (counter) =>
          counter?.camp_step_id?._id === step?.camp_step_id?.camp_step_id?._id
      );

      return {
        ...step,
        data: matchingCounter ? matchingCounter?.data : null,
        stepCounterNumber: matchingCounter
          ? matchingCounter?.camp_counter_id?.counter_number
          : null,
        stepDataCreatedAt: matchingCounter ? matchingCounter?.createdAt : null,
        stepDataCreatedBy: matchingCounter ? matchingCounter?.created_by : null,
      };
    });

    setLocalCampSteps(clonedCampSteps);
  }, [campSteps, campData]);

  const [selected, setSelected] = useState(null);
  const [openStepBody, setOpenStepBody] = useState(null);

  const toggleStep = (index) => {
    setOpenStepBody((prevIndex) => (prevIndex === index ? null : index));
  };

  // Extract initial values from localCampSteps and set them
  useEffect(() => {
    const initialValues = {};

    localCampSteps?.forEach((stepItem) => {
      stepItem?.camp_step_id?.required_data_id?.forEach((fieldItem) => {
        const fieldName =
          fieldItem?.field_box === "textArea"
            ? `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
            : fieldItem?.field_name;

        initialValues[fieldName] = stepItem?.data?.[fieldName] || "";
      });
    });

    setInitialObject(initialValues);
    setSelected(initialValues?.checked_in_person ?? selected);
  }, [localCampSteps]);

  return (
    <>
      <Formik
        initialValues={initialObject}
        enableReinitialize={true}
        onSubmit={(values, actions) => {}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
            encType="multipart/form-data"
          >
            <div className="w-full flex flex-col justify-start items-start space-y-0 divide-y-2">
              {localCampSteps?.map((stepItem, stepIndex) => {
                const stepName =
                  stepItem?.camp_step_id?.camp_step_id?.camp_step_name;

                return (
                  <div className="w-full py-3" key={stepIndex}>
                    <label
                      className={`cursor-pointer font-bold text-base `}
                      onClick={() => toggleStep(stepIndex)}
                    >
                      {`Step ${stepIndex + 1}: ${
                        stepItem?.camp_step_id?.camp_step_id
                          ?.camp_step_display_name
                      }`}
                    </label>
                    {openStepBody === stepIndex && (
                      <>
                        {stepItem?.data && (
                          <div className="my-2 text-sm font-Inter text-[#7e7c7c]">
                            {`Completed on  ${
                              manipulateDateAndTime(stepItem?.stepDataCreatedAt)
                                ?.date
                            } at ${
                              manipulateDateAndTime(stepItem?.stepDataCreatedAt)
                                ?.time
                            } by ${
                              stepItem?.stepDataCreatedBy?.name
                            } (Counter # ${stepItem?.stepCounterNumber})`}
                          </div>
                        )}
                        <div className="my-2">
                          {stepItem?.camp_step_id?.required_data_id?.map(
                            (fieldItem, fieldIndex) => {
                              const fieldName =
                                fieldItem?.field_box === "textArea"
                                  ? `${stepName}_${fieldItem?.field_name}`
                                  : fieldItem?.field_name;

                              let props = {};
                              if (fieldItem?.field_box === "radio") {
                                props = {
                                  name: fieldItem?.field_name,
                                  id: fieldItem?.field_name,
                                  setSelected,
                                  selected,
                                  isVerified: true,
                                  campStep:
                                    stepItem?.camp_step_id?.camp_step_id?._id,
                                };
                              } else if (fieldItem?.field_box === "file") {
                                props = {
                                  name: fieldItem?.field_name,
                                  id: fieldItem?.field_name,
                                  disabled: true,
                                  placeholder: "Choose File",
                                  className: "",
                                  handleFileChange: (e) => {},
                                  value:
                                    values[fieldItem?.field_name]
                                      ?.beneficiary_form_name || "",
                                  // buttonOnClick: handleUploadButtonClick,
                                  buttonName: "Upload",
                                  buttonDisabled: true,
                                  viewButtonOnckick: () => {
                                    const fileURL =
                                      values[fieldItem?.field_name]
                                        ?.beneficiary_form;
                                    if (fileURL) {
                                      window.open(fileURL, "_blank");
                                    }
                                  },
                                };
                              } else if (fieldItem?.field_box === "textArea") {
                                props = {
                                  name: `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`,
                                  id: fieldItem?.field_name,
                                  disabled: true,
                                  value:
                                    values[
                                      `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                                    ],
                                  placeholder: "",
                                  className: "",
                                };
                              } else if (fieldItem?.field_box === "inputText") {
                                props = {
                                  name: fieldItem?.field_name,
                                  id: fieldItem?.field_name,
                                  type: "text",
                                  placeholder: "",
                                  value: values[fieldItem?.field_name],
                                  className: "",
                                };
                              } else if (
                                fieldItem?.field_box === "singleSelect"
                              ) {
                                props = {
                                  name: fieldItem?.field_name,
                                  id: fieldItem?.field_name,
                                  value:
                                    fieldItem?.option_id
                                      ?.map((item) => ({
                                        label: item?.state_name,
                                        value: item?._id,
                                      }))
                                      ?.find(
                                        (item) =>
                                          item?.value ===
                                          values[fieldItem?.field_name]
                                      ) || null,
                                  options: fieldItem?.option_id?.map(
                                    (item) => ({
                                      label: item?.state_name,
                                      value: item?._id,
                                    })
                                  ),
                                  onChange: (selectedOption) => {
                                    const selectedAccess =
                                      selectedOption?.value || "";
                                  },
                                  disabled: true,
                                  placeholder: "Select",
                                  isSearchable: true,
                                  isClearable: false,
                                };
                              } else if (
                                fieldItem?.field_box === "multiSelect"
                              ) {
                                props = {
                                  name: fieldItem?.field_name,
                                  id: fieldItem?.field_name,
                                  displayValue: "label",
                                  showCheckbox: true,
                                  showArrow: true,
                                  placeholder: "Select",
                                  disabled: true,
                                  options: fieldItem?.option_id?.map(
                                    (item) => ({
                                      label: item?.option_display_name,
                                      value: item?._id,
                                    })
                                  ),
                                  onSelect: (selectedList) => {},
                                  onRemove: (selectedList) => {},
                                  selectedValues: fieldItem?.option_id
                                    ?.map((item) => ({
                                      label: item?.option_display_name,
                                      value: item?._id,
                                    }))
                                    .filter((option) =>
                                      values[fieldItem?.field_name]?.includes(
                                        option.value
                                      )
                                    ),
                                };
                              }

                              return (
                                <div
                                  key={fieldIndex}
                                  className="flex flex-col space-y-2 items-start mb-2 w-full"
                                >
                                  <div className="w-full text-black font-semibold">
                                    {fieldItem?.field_display_name}
                                  </div>
                                  <div className="w-full">
                                    {stepItem?.data ? (
                                      <>
                                        {getFieldBox(
                                          fieldItem?.field_box,
                                          props
                                        )}
                                        {/* {stepItem?.data[fieldName]} */}
                                      </>
                                    ) : (
                                      <>
                                        {getFieldBox(
                                          fieldItem?.field_box,
                                          props
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        {/* )} */}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
