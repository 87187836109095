/**
 * Schedule Camp Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { endPoints, httpRequest } from "../../services";
import {
  allScheduleCampList,
  assignBeneficiariestoCamp,
  assignUsertoCampCounters,
  createScheduleCamp,
  createScheduledCampStepSequence,
  deleteBeneficiaryFromQuery,
  deleteBeneficiaryQueryById,
  deleteCampCounterByCounterId,
  deleteScheduleCamp,
  generatePDFOfAssessment,
  getAllAssignBeneficiariesOfCamp,
  getAllBeneficiariesOfCampByScheduleCampId,
  getAllBeneficiariesOfCampByScheduleCampIdForCheckIn,
  getAllBeneficiaryQueryDataByScheduleCampId,
  getAllCampsForSelectBox,
  getAllMyCampsList,
  getAllScheduledCampStepSequence,
  getallStateWithScheduleCampCount,
  getBeneficiariesQueryDataOfCamp,
  getBeneficiaryCountAndIdAssignedWithoutQuery,
  getBeneficiaryQueryDataById,
  getCampCenterByStateId,
  getScheduleCampById,
  getScheduleCampCountersByScheduleCampId,
  getScheduleCampStepById,
  getStepAndCounterOfUser,
  getTotalAssigndBeneficiariesOfCampByScheduledId,
  removeQueryThatNotAssignedToCamp,
  setAllAssinBeneficiayDataList,
  setAllBeneficiariesListForCamp,
  setAllCampListForSelectBox,
  setAllMyCamps,
  setAllScheduleCampList,
  setAllStateWithScheduleCampCount,
  setBeneficiariesQueryList,
  setBeneficiariesWithoutAssignFormQueryList,
  setCampCenterOptionsByState,
  setCampUserCounterData,
  setScheduleCamp,
  setScheduleCampStepsDataList,
  updateBeneficiaryQueryDataById,
  updateRequiredDataOfCampSteps,
  updateScheduleCampById,
  updateScheduledCampStepSequence,
  updateToggleForAvailableUnavailable,
} from "./slice";
import { formatQuery } from "../../utils/utils";
import { setFullScreenLoading } from "../common";
import { setHtmlOfPdf } from "../beneficiary_management/slice";

/**
 * Get all Camp Center Data list
 * @param {*}
 */
function* allScheduleCampListSaga(action) {
  const { search, filter, page, perPage, onSuccess, stateId, onFailure } =
    action.payload;
  try {
    const response = yield httpRequest.post(endPoints.listScheduleCamp, {
      search,
      filter,
      page,
      responsePerPage: perPage,
      state_id: stateId,
    });
    yield onSuccess({ message: response?.message });
    yield put(setAllScheduleCampList(response?.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Create Camp Center
 * @param {*}
 */
function* createScheduleCampSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const {
      state,
      camp_location_id,
      camp_type_id,
      max_numbers,
      from_date,
      to_date,
      from_time,
      to_time,
      notes,
      instructions,
    } = values;
    const response = yield httpRequest.post(endPoints.createScheduleCamp, {
      state,
      camp_location_id,
      camp_type_id,
      max_numbers,
      from_date,
      to_date,
      from_time,
      to_time,
      notes,
      instructions,
    });
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update Camp Center
 * @param {*}
 */
function* updateScheduleCampSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const {
      id,
      state,
      camp_location_id,
      camp_type_id,
      max_numbers,
      from_date,
      to_date,
      from_time,
      to_time,
      notes,
      instructions,
    } = values;
    const response = yield httpRequest.put(endPoints.updateScheduleCamp, {
      id,
      state,
      camp_location_id,
      camp_type_id,
      max_numbers,
      from_date,
      to_date,
      from_time,
      to_time,
      notes,
      instructions,
    });
    yield put(setScheduleCamp(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Camp Center By Id
 * @param {*} Id
 */
function* getScheduleCampByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getScheduleCampById}/${id}`
    );

    yield put(setScheduleCamp(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Delete Camp Center
 * @param {*} Id
 */
function* deleteScheduleCampSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deleteUser}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Schedule Camp Step By Id
 * @param {*} Id
 */
function* getScheduleCampStepByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.scheduleCampStepByScheduleCampId}/${id}`
    );

    yield onSuccess({ message: response?.message, data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  //Schedule Camp Step Sequence
 * @param {*}
 */
function* createScheduleCampStepSequenceSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { scheduled_camp_id, camp_flow } = values;
    const response = yield httpRequest.post(
      endPoints.createScheduleCampStepSequence,
      {
        scheduled_camp_id,
        camp_flow,
      }
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  // Update Schedule Camp Step Sequence
 * @param {*}
 */
function* updateScheduleCampStepSequenceSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { _id, scheduled_camp_id, camp_flow } = values;
    const response = yield httpRequest.put(
      endPoints.updateScheduleCampStepSequence,
      {
        id: _id,
        scheduled_camp_id,
        camp_flow,
      }
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Camp Step Sequence Data list
 * @param {*}
 */
function* allScheduleCampStepSequenceSaga(action) {
  try {
    const response = yield httpRequest.post(endPoints.allScheduleCampSteps);
    yield put(setScheduleCampStepsDataList(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  //Get all Counter of Schedule Camp By Schedule Camp Id
 * @param {*}
 */
function* allCountersScheduleCampByScheduleCampIdSaga(action) {
  const { scheduled_camp_id, onSuccess, onFailure } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.scheduleCampCounterByScheduleCampId,
      {
        scheduled_camp_id,
      }
    );
    yield onSuccess({ message: response?.message, data: response?.data });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 *  // Assign User to camp
 * @param {*}
 */
function* assignUsertoCampCountersSaga(action) {
  try {
    const { counter_data, onSuccess } = action.payload;
    const response = yield httpRequest.put(endPoints.assignUsertoCounter, {
      counter_data,
    });
    yield onSuccess({ message: response?.data?.message, data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Query Data from conditions
 * @param {*}
 */
function* getBeneficiariesQueryDataSaga(action) {
  try {
    const { scheduled_camp_id, conditions, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.getQueryData, {
      scheduled_camp_id,
      condition: conditions,
    });
    yield onSuccess({ message: response?.message, data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  Assign Beneficiary to camp
 * @param {*}
 */
function* assignBeneficiariestoCampSaga(action) {
  try {
    const { scheduled_camp_id, beneficiary_id, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.assignBeneficary, {
      scheduled_camp_id,
      beneficiary_id,
    });
    yield onSuccess({ message: response?.message, data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * get all Assigned Beneficiary of camp
 * @param {*}
 */
function* getAllAssignBeneficiaryOfCampSaga(action) {
  const { beneficiary_ids, search, page, perPage, onSuccess, onFailure } =
    action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allAssignedBeneficaryList,
      {
        beneficiary_id: beneficiary_ids,
        search,
        page,
        responsePerPage: perPage,
      }
    );

    yield put(setAllAssinBeneficiayDataList(response?.data));
    yield onSuccess();
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * get Total Assignd Beneficiary Of Camp By Schedule Camp Id
 * @param {*}
 */
function* getTotalAssigndBeneficiaryOfCampByScheduleCampIdSaga(action) {
  const { scheduled_camp_id, search, page, perPage, onSuccess, onFailure } =
    action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.totalAssignedBeneficaryList,
      {
        id: scheduled_camp_id,
        search,
        page,
        responsePerPage: perPage,
      }
    );

    yield put(setAllAssinBeneficiayDataList(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 *  get All Camp List For Select Box
 * @param {*}
 */
function* getAllCampListForSelectBoxSaga(action) {
  try {
    const { search, filter, page, perPage } = action.payload;
    const response = yield httpRequest.post(endPoints.campsListForSelectBox, {
      search,
    });
    yield put(setAllCampListForSelectBox(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  get All Beneficiaries Of Camp By Schedule Camp Id
 * @param {*}
 */
function* getBeneficiariesOfScheduledCampSaga(action) {
  const {
    scheduled_camp_id,
    search,
    stateId,
    districtId,
    status,
    page,
    filter,
    perPage,
    onSuccess,
    onFailure,
  } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryListofScheduledCampById,
      {
        scheduled_camp_id,
        search,
        state: stateId,
        district: districtId,
        pinCode: filter.pinCode,
        page,
        responsePerPage: perPage,
        status,
      }
    );
    yield put(setAllBeneficiariesListForCamp(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 *  get All Beneficiaries Of Camp By Schedule Camp Id for checkin steps
 * @param {*}
 */
function* getBeneficiariesOfScheduledCampForCheckInStepsSaga(action) {
  const {
    scheduled_camp_id,
    search,
    stateId,
    districtId,
    status,
    page,
    filter,
    perPage,
    onSuccess,
    onFailure,
  } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryListofScheduledCampByIdForCheckin,
      {
        scheduled_camp_id,
        search,
        state: stateId,
        district: districtId,
        pinCode: filter.pinCode,
        page,
        responsePerPage: perPage,
        status,
      }
    );
    yield put(setAllBeneficiariesListForCamp(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 *  get All Beneficiary Query Data List By scheduled camp id
 * @param {*}
 */
function* getAllBeneficiaryQueryDataListSaga(action) {
  const { scheduled_camp_id, onSuccess, onFailure } = action.payload;
  try {
    const response = yield call(
      httpRequest.get,
      `${endPoints.allBeneficiaryQueryListByScheduledCampId}/${scheduled_camp_id}`
    );
    const formatData = response?.data?.map((item) => ({
      ...item,
      queryText: `Beneficiaries ${formatQuery(item?.condition)}`,
    }));

    yield put(setBeneficiariesQueryList({ data: formatData }));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 *  Delete query whose beneficiary is not assigned to the camp
 * @param {*}
 */
function* removeQueryThatNotAssignedToCampSaga(action) {
  try {
    const { scheduled_camp_id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.removeQueryNotAssignBeneficiay}/${scheduled_camp_id}`
    );
    const formatData = response?.data?.map((item) => ({
      ...item,
      queryText: `Beneficiaries ${formatQuery(item?.condition)}`,
    }));

    yield put(setBeneficiariesQueryList({ data: formatData }));
    yield onSuccess({ message: response.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  get  Beneficiary Query Data  By  id
 * @param {*}
 */
function* getBeneficiaryQueryDataByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.beneficiaryQueryById}/${id}`
    );

    yield onSuccess({ data: response.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  update Beneficiary Query Data By query Id
 * @param {*}
 */
function* updateBeneficiaryQueryDataByIdSaga(action) {
  try {
    const { id, conditions, onSuccess } = action.payload;
    const response = yield httpRequest.put(endPoints.updateQueryById, {
      id,
      condition: conditions,
    });

    yield onSuccess({ data: response.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  Delete  Beneficiary Query Data  By  id
 * @param {*}
 */
function* deleteBeneficiaryQueryDataByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deleteQueryById}/${id}`
    );

    yield onSuccess({ message: response.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  get All My Camps List Data
 * @param {*}
 */
function* allMyCampsListSaga(action) {
  try {
    const response = yield httpRequest.get(endPoints.allMyCamp);

    yield put(setAllMyCamps(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  delete Beneficiary From the Query Data
 * @param {*}
 */
function* deleteBeneficiaryFromQueryDataSaga(action) {
  try {
    const { id, beneficiary_id, onSuccess } = action.payload;
    const response = yield httpRequest.post(
      endPoints.removeBeneficiaryFromQuery,
      { scheduled_camp_id: id, beneficiary_id }
    );
    yield onSuccess({ message: response?.data?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  update Required Data Of Camp Steps
 * @param {*}
 */
function* updateRequiredDataOfStepsSaga(action) {
  try {
    yield put(setFullScreenLoading(true));
    const { values, onSuccess } = action.payload;
    const { scheduled_camp_id, beneficiary_id, current_status, camp_flow } =
      values;
    const response = yield httpRequest.put(endPoints.updateRequiredData, {
      scheduled_camp_id,
      beneficiary_id,
      camp_flow,
      current_status,
    });
    yield put(setFullScreenLoading(false));
    yield onSuccess({ message: response?.data?.message, data: response?.data });
  } catch (error) {
    yield put(setFullScreenLoading(false));
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  get Step And Counter Of User
 * @param {*}
 */
function* getStepAndCounterOfUserSaga(action) {
  try {
    const { scheduled_camp_id, onSuccess } = action.payload;

    const response = yield httpRequest.post(endPoints.userStepAndCounter, {
      scheduled_camp_id,
    });
    yield put(setCampUserCounterData(response?.data));
    yield onSuccess({ message: response?.message, data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  get Camp Centers By Satate Id
 * @param {*}
 */
function* getCampCenterBySatateId(action) {
  try {
    const { stateId } = action.payload;

    const response = yield call(
      httpRequest.get,
      `${endPoints.getCampCenterByState}/${stateId}`
    );
    yield put(setCampCenterOptionsByState(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all State With Schedule Camp Count
 * @param {*}
 */
function* allStateWithScheduleCampCountListSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.get(
      endPoints.stateWithCountScheduleCamp
    );
    yield put(setAllStateWithScheduleCampCount(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * update Toggle For Available Or Unavailable
 * @param {*}
 */
function* updateToggleForAvailableUnavailableSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.put,
      `${endPoints.updateToggle}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * delete Camp Counter By Counter Id
 * @param {*}
 */
function* deleteCampCounterByCounterIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deleteCampCounter}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * get Beneficiary Count And Ids, beneficiay Assigned in camp Without Query
 * @param {*}
 */
function* getBeneficiaryCountAndIdAssignedWithoutQuerySaga(action) {
  const { scheduled_camp_id, onSuccess, onFailure } = action.payload;
  try {
    const response = yield call(
      httpRequest.get,
      `${endPoints.getBeneficaryCountAssignedWithoutQuery}/${scheduled_camp_id}`
    );

    yield put(
      setBeneficiariesWithoutAssignFormQueryList({ data: response?.data })
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Generate pfd OF beneficiary
 * @param {*}
 */
function* generatePDFOfAssessmentSaga(action) {
  try {
    const { beneficiary_id, scheduled_camp_id, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.generatePdfAssessment, {
      beneficiary_id,
      scheduled_camp_id,
    });

    yield put(setHtmlOfPdf(response?.data));
    yield onSuccess({ message: response?.message, data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* scheduleCampManagementSaga() {
  yield takeLatest(allScheduleCampList, allScheduleCampListSaga);
  yield takeLatest(createScheduleCamp, createScheduleCampSaga);
  yield takeLatest(updateScheduleCampById, updateScheduleCampSaga);
  yield takeLatest(getScheduleCampById, getScheduleCampByIdSaga);
  yield takeLatest(deleteScheduleCamp, deleteScheduleCampSaga);
  yield takeLatest(getCampCenterByStateId, getCampCenterBySatateId);

  //Schedule Camp Step Sequence
  yield takeLatest(
    createScheduledCampStepSequence,
    createScheduleCampStepSequenceSaga
  );
  yield takeLatest(
    updateScheduledCampStepSequence,
    updateScheduleCampStepSequenceSaga
  );
  yield takeLatest(
    getAllScheduledCampStepSequence,
    allScheduleCampStepSequenceSaga
  );
  yield takeLatest(getScheduleCampStepById, getScheduleCampStepByIdSaga);
  //Schedule Camp Counter
  yield takeLatest(
    getScheduleCampCountersByScheduleCampId,
    allCountersScheduleCampByScheduleCampIdSaga
  );
  yield takeLatest(assignUsertoCampCounters, assignUsertoCampCountersSaga);
  yield takeLatest(assignBeneficiariestoCamp, assignBeneficiariestoCampSaga);
  yield takeLatest(
    getBeneficiariesQueryDataOfCamp,
    getBeneficiariesQueryDataSaga
  );
  yield takeLatest(
    getAllAssignBeneficiariesOfCamp,
    getAllAssignBeneficiaryOfCampSaga
  );
  yield takeLatest(
    getTotalAssigndBeneficiariesOfCampByScheduledId,
    getTotalAssigndBeneficiaryOfCampByScheduleCampIdSaga
  );
  yield takeLatest(getAllCampsForSelectBox, getAllCampListForSelectBoxSaga);
  yield takeLatest(
    getAllBeneficiariesOfCampByScheduleCampId,
    getBeneficiariesOfScheduledCampSaga
  );
  yield takeLatest(
    getAllBeneficiariesOfCampByScheduleCampIdForCheckIn,
    getBeneficiariesOfScheduledCampForCheckInStepsSaga
  );
  yield takeLatest(
    deleteCampCounterByCounterId,
    deleteCampCounterByCounterIdSaga
  );
  //Temp Query
  yield takeLatest(
    getAllBeneficiaryQueryDataByScheduleCampId,
    getAllBeneficiaryQueryDataListSaga
  );
  yield takeLatest(
    removeQueryThatNotAssignedToCamp,
    removeQueryThatNotAssignedToCampSaga
  );
  yield takeLatest(
    updateBeneficiaryQueryDataById,
    updateBeneficiaryQueryDataByIdSaga
  );
  yield takeLatest(
    getBeneficiaryQueryDataById,
    getBeneficiaryQueryDataByIdSaga
  );
  yield takeLatest(
    deleteBeneficiaryQueryById,
    deleteBeneficiaryQueryDataByIdSaga
  );
  yield takeLatest(
    deleteBeneficiaryFromQuery,
    deleteBeneficiaryFromQueryDataSaga
  );
  //  My Camps
  yield takeLatest(getAllMyCampsList, allMyCampsListSaga);
  yield takeLatest(
    updateToggleForAvailableUnavailable,
    updateToggleForAvailableUnavailableSaga
  );
  // UPDATE REQUIRED DATA FOR STEPS
  yield takeLatest(
    updateRequiredDataOfCampSteps,
    updateRequiredDataOfStepsSaga
  );
  yield takeLatest(getStepAndCounterOfUser, getStepAndCounterOfUserSaga);
  yield takeLatest(
    getallStateWithScheduleCampCount,
    allStateWithScheduleCampCountListSaga
  );
  yield takeLatest(
    getBeneficiaryCountAndIdAssignedWithoutQuery,
    getBeneficiaryCountAndIdAssignedWithoutQuerySaga
  );
  yield takeLatest(generatePDFOfAssessment, generatePDFOfAssessmentSaga);
}
