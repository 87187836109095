/**
 * permissions Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { endPoints, httpRequest } from "../../services";
import {
  allPermissionList,
  createPermission,
  deletePermission,
  getPermissionById,
  setAllPermissionDataList,
  updatePermissionById,
} from "./slice";

/**
 * Get all Permission list
 * @param {*}
 */
function* allPermissionListSaga(action) {
  try {
    const { search, page, perPage, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allPermissionsList, {
      search,
      page,
      responsePerPage: perPage,
    });
    yield onSuccess({ message: response?.message });
    yield put(setAllPermissionDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Create Permission with fields
 * @param {*} *fields
 */
function* createPermissionSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { name, permission_display_name, permission_description } = values;
    const response = yield httpRequest.post(endPoints.createPermission, {
      name,
      permission_display_name,
      permission_description,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update Permission By Id
 * @param {*} *fields
 */
function* updatePermissionByIdSaga(action) {
  try {
    const { updatedValues, onSuccess } = action.payload;
    const {
      id,
      name,
      permission_display_name,
      permission_description,
      active,
    } = updatedValues;
    const response = yield httpRequest.put(endPoints.updatePermissionById, {
      id,
      name,
      permission_display_name,
      permission_description,
      active,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Permission By Id
 * @param {*} Id
 */
function* getPermissionByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getPermissionById}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Delete Permission
 * @param {*} Id
 */
function* deletePermissionSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deletePermission}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* permissionManagementSaga() {
  yield takeLatest(allPermissionList, allPermissionListSaga);
  yield takeLatest(createPermission, createPermissionSaga);
  yield takeLatest(updatePermissionById, updatePermissionByIdSaga);
  yield takeLatest(getPermissionById, getPermissionByIdSaga);
  yield takeLatest(deletePermission, deletePermissionSaga);
}
