/**
 * Camp Center Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { endPoints, httpRequest } from "../../services";
import {
  allCampCenterList,
  createCampCenter,
  deleteCampCenter,
  getAllStateWithCampCenterCount,
  getCampCenterById,
  setAllCampCenterList,
  setAllStateWithCampCenterCount,
  setCampCenter,
  updateCampCenterById,
  updateCampCenterStatusById,
} from "./slice";

/**
 * Get all Camp Center Data list
 * @param {*}
 */
function* allCampCenterListSaga(action) {
  const { search, stateId, page, perPage, onSuccess, onFailure } =
    action.payload;
  try {
    const response = yield httpRequest.post(endPoints.listCampCenter, {
      search,
      stateId,
      page,
      responsePerPage: perPage,
    });
    yield onSuccess({ message: response?.message });
    yield put(setAllCampCenterList(response?.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Create Camp Center
 * @param {*}
 */
function* createCampCenterSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const {
      camp_location_code,
      camp_location_name,
      address_1,
      address_2,
      state,
      district,
      pin_code,
      geo_coordinates,
      contact_person_name,
      contact_person_phone,
      camp_notes,
      is_active,
    } = values;
    const response = yield httpRequest.post(endPoints.createCampCenter, {
      camp_location_code,
      camp_location_name,
      address_1,
      address_2,
      state,
      district,
      pin_code,
      geo_coordinates,
      contact_person_name,
      contact_person_phone,
      camp_notes,
      is_active,
    });
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update Camp Center
 * @param {*}
 */
function* updateCampCenterSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const {
      _id,
      camp_location_code,
      camp_location_name,
      address_1,
      address_2,
      state,
      district,
      pin_code,
      geo_coordinates,
      contact_person_name,
      contact_person_phone,
      camp_notes,
      is_active,
    } = values;
    const response = yield httpRequest.put(endPoints.updateCampCenter, {
      id: _id,
      camp_location_code,
      camp_location_name,
      address_1,
      address_2,
      state,
      district,
      pin_code,
      geo_coordinates,
      contact_person_name,
      contact_person_phone,
      camp_notes,
      is_active,
    });
    yield put(setCampCenter(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update Camp Center status
 * @param {*}
 */
function* updateCampCenterStatusSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { id } = values;
    const response = yield call(
      httpRequest.get,
      `${endPoints.updateCampCenterStatus}/${id}`
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Camp Center By Id
 * @param {*} Id
 */
function* getCampCenterByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getCampCenterById}/${id}`
    );

    yield onSuccess({ message: response?.message });
    yield put(setCampCenter(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Delete Camp Center
 * @param {*} Id
 */
function* deleteCampCenterSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deleteCampCenter}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Camp Center Data list
 * @param {*}
 */
function* allStateWithCampCenterCountListSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.get(endPoints.stateWithCountCampCenter);
    yield put(setAllStateWithCampCenterCount(response));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* campCenterManagementSaga() {
  yield takeLatest(allCampCenterList, allCampCenterListSaga);
  yield takeLatest(createCampCenter, createCampCenterSaga);
  yield takeLatest(updateCampCenterById, updateCampCenterSaga);
  yield takeLatest(getCampCenterById, getCampCenterByIdSaga);
  yield takeLatest(deleteCampCenter, deleteCampCenterSaga);
  yield takeLatest(updateCampCenterStatusById, updateCampCenterStatusSaga);
  yield takeLatest(
    getAllStateWithCampCenterCount,
    allStateWithCampCenterCountListSaga
  );
}
