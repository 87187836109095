/**
 * Routes Constants
 * @format
 */

// Auth Routes
export const AuthRoutes = {
  LoginScreen: "/login",
};

// Routes
export const MainRoutes = {
  DashboardScreen: "/dashboard",
};

//Beneficiary Routes
export const BeneficiaryRoutes = {
  BeneficiaryVerification: "/beneficiary/Verification",
  BeneficiaryAssessment: "/beneficiary/Assessments",
  BeneficiaryFabrication: "/beneficiary/Fabrication",
  BeneficiaryDistribution: "/beneficiary/Distribution",
  BeneficiaryList: "/beneficiaryList",
};

//User Routes
export const UserRoutes = {
  UserList: "/userList",
};

//Role Routes
export const RoleRoutes = {
  RoleList: "/roleList",
};

//Camp Routes
export const CampsRoutes = {
  CampsList: "/scheduleCampsList",
  MyCamp: "/myCamp/:id",
  MyCampList: "/myCampList",
};

//Camp Center Routes
export const CampCentersRoutes = {
  CampCentersList: "/campCentersList",
};

//Camp Center Routes
export const CampOverviewRoutes = {
  CampOverviewList: "/campOverview",
};

// Fabrication Routes
export const FabricationRoutes = {
  FabricationListScreen: "/fabricationList",
  BeneficiaryListOfFabricationScreen: "/beneficiaryListFabrication/:id",
};
