/**
 * Form Upload Component
 * @flow
 * @format
 */

import React from "react";
import CustomButton from "./customButtion";
import { Cross } from "../assets/icons/Cross";

const CustomFormUploadInput = (props) => {
  const {
    name,
    id,
    handleFileChange,
    disabled,
    placeholder,
    value,
    className,
    buttonOnClick,
    buttonName,
    buttonIcon,
    buttonSize,
    buttonDisabled,
    selectedFile,
    viewButtonOnckick,
    crossButtonOnckick,
    crossButtonDisabled,
    fileInputRef,
  } = props;

  return (
    <>
      <div className="relative flex flex-row justify-between space-x-3 w-full items-center border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover">
        <input
          type="text"
          className={` h-[44px] mt-0 py-1 px-2  cursor-text rounded-[12px] w-[85%] ${className}`}
          placeholder={placeholder || "Select a file..."}
          value={value}
          readOnly
          // disabled={disabled}
        />

        {/* Hidden file input */}
        <input
          type="file"
          id={id}
          name={name}
          className="hidden"
          onChange={handleFileChange}
          ref={fileInputRef}
          disabled={disabled}
        />

        <div className="!absolute right-2 top-1.5 h-[70%] flex flex-row items-center justify-between space-x-3">
          {value ? (
            <div className="flex flex-row justify-between items-center space-x-2">
              {!crossButtonDisabled && (
                <div className="cursor-pointer" onClick={crossButtonOnckick}>
                  <Cross width={32} height={32} />
                </div>
              )}

              <div className="cursor-pointer" onClick={viewButtonOnckick}>
                <span className="text-[13px] font-medium underline text-blue-500">
                  View
                </span>
              </div>
            </div>
          ) : (
            <CustomButton
              name={"Select file"}
              type="button"
              loading={false}
              disabled={buttonDisabled}
              onClick={() => {
                // Trigger the file input click
                document.getElementById(id).click();
              }}
              className={
                "rounded-[10px] py-0 text-[14px] h-[100%] bg-[#4AB8FF]"
              }
              size={buttonSize}
              icon={buttonIcon}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomFormUploadInput;
