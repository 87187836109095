/**
 * Add user Model
 * @format
 */

import React, { useEffect, useState } from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { Switch } from "@material-tailwind/react";
import { toast } from "react-toastify";
import { UserFields } from "../../../constants";
import Multiselect from "multiselect-react-dropdown";
import { selectAllRoleDataList } from "../../role_management/selector";
import { allAccessLevelList } from "../../access_level_management/slice";
import { selectAllAccessLevelDataList } from "../../access_level_management/selector";
import { Delete } from "../../../assets/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { allRoleList } from "../../role_management/slice";
import { createUser, getUserFromSSSApi } from "../slice";
import { Cross } from "../../../assets/icons/Cross";
import {
  getAllDistrict,
  getAllState,
  getAllUserType,
  getDistrictByStateId,
  selectDistrict,
  selectDistrictByState,
  selectState,
  selectUserAllPermissions,
  selectUserType,
} from "../../common";
import MultipleTagsInput from "../../../components/multipleTagInput";
import SelectDropDown from "../../../components/selectDropDown";

const AddUserModel = ({ toggleModal, getData }) => {
  const dispatch = useDispatch(); // For generating unique IDs
  const [userStatus, setUserStatus] = useState(true);
  const [userApiData, setUserApiData] = useState({});
  const [selected, setSelected] = useState(false);
  const [dataFromSSS, setDataFromSSS] = useState(false);
  const userPermissions = useSelector(selectUserAllPermissions) || {};

  // Validations
  const validationSchema = Yup.object().shape({
    [UserFields.MOBILE_NUMBER]: Yup.string()
      .required("Mobile No. is required")
      .matches(/^\d+$/, "Only numeric digits are allowed")
      .min(9, "Mobile no. must be at least 9 digits")
      .max(10, "Mobile no. must not exceed 10 digits"),
    [UserFields.NAME]: Yup.string().required("Name is required"),
    [UserFields.POLITICAL_STATE_ID]: Yup.string().required("State is required"),
    [UserFields.POLITICAL_DISTRICT_ID]: Yup.string().required(
      "District is required"
    ),
    // [UserFields.EMAIL]: Yup.string()
    //   .email("Invalid email format")
    //   .required("Email is required"),
    [UserFields.USER_TYPE]: Yup.array()
      .of(Yup.string().required("Invalid user type"))
      .min(1, "Select at least one user type")
      .required("User type is required"),
    [UserFields.IS_ACTIVE]: Yup.boolean(),
  });

  const initialValues = {
    [UserFields.MOBILE_NUMBER]: "",
    [UserFields.MEMBER_ID]: "",
    [UserFields.ROLE]: [],
    [UserFields.USER_TYPE]: [],
    [UserFields.NAME]: "",
    [UserFields.EMAIL]: "",
    [UserFields.GENDER]: "",
    [UserFields.PIN_CODE]: "",
    [UserFields.IS_ACTIVE]: true,
    [UserFields.SAMITHI_ID]: "",
    [UserFields.SAMITHI_NAME]: "",
    [UserFields.SSS_DISTRICT_ID]: "",
    [UserFields.SSS_DISTRICT_NAME]: "",
    [UserFields.SSS_STATE_ID]: "",
    [UserFields.SSS_STATE_NAME]: "",
    [UserFields.POLITICAL_STATE_NAME]: "",
    [UserFields.POLITICAL_STATE_ID]: "",
    [UserFields.POLITICAL_DISTRICT_NAME]: "",
    [UserFields.POLITICAL_DISTRICT_ID]: "",
    [UserFields.DESIGNATION_NAME]: "",
    access_level: [{ access_level_id: "", locale: [] }],
  };

  const [initialObject, setInitialObject] = useState(initialValues);

  const getRoles = () => {
    dispatch(
      allRoleList({
        search: "",
        // page: 1,
        // perPage: 10,
        onSuccess: (props) => {},
      })
    );
  };

  const getAccessLevel = () => {
    dispatch(
      allAccessLevelList({
        search: "",
        // page: 1,
        // perPage: 2,
        onSuccess: (props) => {},
      })
    );
  };

  const getDistrict = () => {
    dispatch(
      getAllDistrict({
        // district_name: "",
        // state_code: 1,
        // sai_connect_state_id: 2,
        onSuccess: (props) => {},
      })
    );
  };

  const getDistrictListByState = (id) => {
    dispatch(
      getDistrictByStateId({
        stateId: id,
      })
    );
  };

  const getState = () => {
    dispatch(
      getAllState({
        state_name: "",
        // iso_code: "",
        onSuccess: (props) => {},
      })
    );
  };

  const getUserType = () => {
    dispatch(
      getAllUserType({
        search: "",
        onSuccess: (props) => {},
      })
    );
  };

  useEffect(() => {
    getRoles();
    getAccessLevel();
    getDistrict();
    getState();
    getUserType();
    if (userApiData?.MemberId) {
      const {
        MemberName,
        MemberId,
        MobileNumber,
        EmailID,
        SamithiId,
        SamithiName,
        SSSDistrictId,
        SSSDistrictName,
        SSSStateId,
        SSSStateName,
        GenderName,
        PoliticalStateName,
        PoliticalStateId,
        PoliticalDistrictName,
        PoliticalDistrictId,
        PinCode,
        DesignationName,
      } = userApiData;
      const initialApiDataObject = {
        [UserFields.NAME]: MemberName,
        [UserFields.MEMBER_ID]: MemberId,
        // [UserFields.SSS_MOBILE_NUMBER]: MobileNumber,
        [UserFields.MOBILE_NUMBER]: MobileNumber,
        [UserFields.EMAIL]: EmailID,
        [UserFields.SAMITHI_ID]: SamithiId,
        [UserFields.SAMITHI_NAME]: SamithiName,
        [UserFields.SSS_DISTRICT_ID]: SSSDistrictId,
        [UserFields.SSS_DISTRICT_NAME]: SSSDistrictName,
        [UserFields.SSS_STATE_ID]: SSSStateId,
        [UserFields.SSS_STATE_NAME]: SSSStateName,
        [UserFields.GENDER]: GenderName,
        [UserFields.POLITICAL_STATE_NAME]: PoliticalStateName,
        [UserFields.POLITICAL_STATE_ID]: PoliticalStateId,
        [UserFields.POLITICAL_DISTRICT_NAME]: PoliticalDistrictName,
        [UserFields.POLITICAL_DISTRICT_ID]: PoliticalDistrictId,
        [UserFields.PIN_CODE]: PinCode,
        [UserFields.DESIGNATION_NAME]: DesignationName || "",
        [UserFields.ROLE]: [],
        [UserFields.USER_TYPE]: [],
        [UserFields.IS_ACTIVE]: true,
        access_level: [{ access_level_id: "", locale: [] }],
      };

      // Conditionally add UserFields.NUMBER if selected is true
      if (selected) {
        initialApiDataObject[UserFields.NUMBER] = MemberId;
      }

      setInitialObject(initialApiDataObject);

      const selectedState = stateOptions.find(
        (state) => state?.value === Number(PoliticalStateId)
      );
      if (selectedState?.state_id) {
        getDistrictListByState(selectedState?.state_id);
      }
    }
  }, [userApiData]);

  const { data: roleData = [] } = useSelector(selectAllRoleDataList) || {};
  const rolesOptions = roleData?.map((item) => ({
    label: item.role_name,
    value: item._id,
  }));

  const { data: accessLevelData = [] } =
    useSelector(selectAllAccessLevelDataList) || {};
  const accessLevelIds =
    accessLevelData?.reduce((acc, item) => {
      acc[item.name] = item._id;
      return acc;
    }, {}) || {};

  const accessLevelOptions = accessLevelData?.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const { data: district = [] } = useSelector(selectDistrict) || {};
  const districtOptions = district?.map((item) => ({
    group: item?.state_data?.state_name,
    label: item?.district_name,
    value: item?._id,
  }));

  const { data: districtByState = [] } =
    useSelector(selectDistrictByState) || {};
  const districtByStateOptions = districtByState?.map((item) => ({
    label: item?.district_name,
    value: item?.sai_connect_district_id,
  }));

  const { data: state = [] } = useSelector(selectState) || {};
  const stateOptionsForLocale = state?.map((item) => ({
    group: "India",
    label: item?.state_name,
    value: item?._id,
  }));
  const stateOptions = state?.map((item) => ({
    label: item?.state_name,
    value: item?.sai_connect_state_id,
    state_id: item?._id,
  }));

  const { data: userType = [] } = useSelector(selectUserType) || {};
  const userTypeOptions = userType?.map((item) => ({
    label: item?.user_type_name,
    value: item?._id,
  }));

  const localeOptions = {
    national: [{ label: "India", value: "India" }],
    state: stateOptionsForLocale,
    district: districtOptions,
  };

  const getLocaleOptions = (access_level_id) => {
    if (access_level_id === accessLevelIds?.National)
      return localeOptions.national || [];
    if (access_level_id === accessLevelIds?.State)
      return localeOptions.state || [];
    if (access_level_id === accessLevelIds?.District)
      return localeOptions.district || [];
    return [];
  };
  return (
    <>
      <Formik
        initialValues={initialObject}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const updateValues = {
            ...values,
            [UserFields.MOBILE_NUMBER]: values[UserFields.MOBILE_NUMBER],
            // selected === true
            //   ? values[UserFields.SSS_MOBILE_NUMBER]
            //   : selected === false
            //   ? values[UserFields.MOBILE_NUMBER]
            //   : "",

            [UserFields.MEMBER_ID]:
              selected === true ? values[UserFields.MEMBER_ID] : "",
          };
          delete values[UserFields.NUMBER];
          if (userPermissions?.Create_User === true) {
            dispatch(
              createUser({
                values: updateValues,
                onSuccess: (props) => {
                  toast.success(props?.message);
                  toggleModal();
                  const search = "";
                  getData(search);
                },
              })
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => {
          const orgUserId = userType?.find(
            (item) => item?.user_type_name === "Org user"
          )?._id;
          // const isOrgUser =
          //   values[UserFields.USER_TYPE].includes(orgUserId) || false;

          return (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            >
              <div className="fixed inset-0 z-50 flex justify-end">
                <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
                <div
                  data-aos-easing="linear"
                  data-aos="fade-left"
                  className="relative w-[43%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
                >
                  <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                    <h2 className="text-xl font-bold">Create New User</h2>
                    <button
                      onClick={toggleModal}
                      className="text-xl font-semibold"
                    >
                      <Cross width={32} height={32} />
                    </button>
                  </div>
                  {/* Modal content */}
                  <div className="w-full flex flex-col space-y-3 p-6">
                    <div className="">
                      <p className="font-bold text-sm mb-3">
                        Does the user have ’Sai Connect ID’ ?
                      </p>
                      <div className="flex space-x-6 w-full">
                        <div className="flex flex-row space-x-3">
                          <label
                            className={`flex items-center space-x-2 cursor-pointer `}
                          >
                            <input
                              type="radio"
                              name="yesNo"
                              value={true}
                              checked={selected === true}
                              onChange={() => {
                                setSelected(true);
                                setDataFromSSS(false);
                                resetForm();
                                setInitialObject(initialValues);
                              }}
                              className={`w-4 h-4 ${
                                selected === true ? "accent-blue-500" : ""
                              }`}
                              disabled={false}
                            />
                            <span className="font-bold text-sm">Yes</span>
                          </label>

                          <label
                            className={`flex items-center space-x-2 cursor-pointer `}
                          >
                            <input
                              type="radio"
                              name="yesNo"
                              value={false}
                              checked={selected === false}
                              onChange={() => {
                                setSelected(false);
                                setDataFromSSS(false);
                                resetForm();
                                setInitialObject(initialValues);
                              }}
                              className={`w-4 h-4 ${
                                selected === false ? "accent-blue-500" : ""
                              }`}
                              disabled={false}
                            />
                            <span className="font-bold text-sm">No</span>
                          </label>
                        </div>
                        <span className="font-semibold text-sm ">
                          (You can only create a camp user in case of ‘No’)
                        </span>
                      </div>
                    </div>
                    {/* Start main contact fields*/}
                    {selected && (
                      <div className="w-full">
                        <label className="text-sm font-bold">
                          Mobile Number/Sai Connect ID
                        </label>
                        <input
                          autoComplete="off"
                          type="tel"
                          name={UserFields.NUMBER}
                          id={UserFields.NUMBER}
                          maxLength={10}
                          onChange={(e) => {
                            handleChange(e);
                            const number = e.target.value;
                            if (number.length === 6) {
                              dispatch(
                                getUserFromSSSApi({
                                  number,
                                  onSuccess: (props) => {
                                    toast.success(props?.message);
                                    setUserApiData(
                                      props.response?.data?.data[0]
                                    );
                                    setDataFromSSS(true);
                                  },
                                })
                              );
                            } else if (number.length === 10) {
                              dispatch(
                                getUserFromSSSApi({
                                  number,
                                  onSuccess: (props) => {
                                    toast.success(props?.message);
                                    setUserApiData(
                                      props.response?.data?.data[0]
                                    );
                                    setDataFromSSS(true);
                                  },
                                })
                              );
                            }
                          }}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={values[UserFields.NUMBER]}
                          onBlur={handleBlur}
                          className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                        />
                        {touched[UserFields.NUMBER] &&
                          errors[UserFields.NUMBER] && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors[UserFields.NUMBER]}
                            </div>
                          )}
                      </div>
                    )}
                    <div className="w-full">
                      <label className="text-[14px] font-bold">
                        Mobile Number
                      </label>
                      <input
                        autoComplete="off"
                        type="tel"
                        name={UserFields.MOBILE_NUMBER}
                        id={UserFields.MOBILE_NUMBER}
                        maxLength={10}
                        onChange={handleChange}
                        disabled={dataFromSSS}
                        value={values[UserFields.MOBILE_NUMBER]}
                        onBlur={handleBlur}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                      />
                      {errors[UserFields.MOBILE_NUMBER] &&
                        touched[UserFields.MOBILE_NUMBER] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[UserFields.MOBILE_NUMBER]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold">Name</label>
                      <input
                        type="text"
                        name={UserFields.NAME}
                        onChange={handleChange}
                        value={values[UserFields.NAME]}
                        onBlur={handleBlur}
                        disabled={dataFromSSS}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                      />
                      {touched[UserFields.NAME] && errors[UserFields.NAME] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[UserFields.NAME]}
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">State</label>
                      <SelectDropDown
                        name={UserFields.POLITICAL_STATE_ID}
                        options={stateOptions}
                        value={
                          stateOptions?.find(
                            (item) =>
                              Number(item?.value) ===
                              Number(values[UserFields.POLITICAL_STATE_ID])
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          const selectedAccess = selectedOption?.value || "";
                          setFieldValue(
                            UserFields.POLITICAL_STATE_ID,
                            selectedAccess
                          );
                          setFieldValue(
                            UserFields.POLITICAL_STATE_NAME,
                            selectedOption?.label
                          );
                          getDistrictListByState(selectedOption?.state_id);
                        }}
                        touched={touched}
                        errors={errors}
                        placeholder={"Select"}
                        isSearchable={true}
                        isClearable={false}
                        disabled={dataFromSSS}
                      />
                      {touched[UserFields.POLITICAL_STATE_ID] &&
                        errors[UserFields.POLITICAL_STATE_ID] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[UserFields.POLITICAL_STATE_ID]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">District</label>
                      <SelectDropDown
                        name={UserFields.POLITICAL_DISTRICT_ID}
                        options={districtByStateOptions}
                        value={
                          districtByStateOptions?.find(
                            (item) =>
                              Number(item?.value) ===
                              Number(values[UserFields.POLITICAL_DISTRICT_ID])
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          const selectedAccess = selectedOption?.value || "";
                          setFieldValue(
                            UserFields.POLITICAL_DISTRICT_ID,
                            selectedAccess
                          );
                          setFieldValue(
                            UserFields.POLITICAL_DISTRICT_NAME,
                            selectedOption?.label
                          );
                        }}
                        touched={touched}
                        errors={errors}
                        placeholder={"Select"}
                        isSearchable={true}
                        isClearable={false}
                        disabled={dataFromSSS}
                      />
                      {touched[UserFields.POLITICAL_DISTRICT_ID] &&
                        errors[UserFields.POLITICAL_DISTRICT_ID] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[UserFields.POLITICAL_DISTRICT_ID]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-[14px] font-bold">Pin Code</label>
                      <input
                        type="text"
                        name={UserFields.PIN_CODE}
                        onChange={handleChange}
                        value={values[UserFields.PIN_CODE]}
                        onBlur={handleBlur}
                        disabled={dataFromSSS}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                      />
                      {touched[UserFields.PIN_CODE] &&
                        errors[UserFields.PIN_CODE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[UserFields.PIN_CODE]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold">Email</label>
                      <input
                        type="email"
                        name={UserFields.EMAIL}
                        onChange={handleChange}
                        value={values[UserFields.EMAIL]}
                        onBlur={handleBlur}
                        disabled={dataFromSSS}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                      />
                      {touched[UserFields.EMAIL] &&
                        errors[UserFields.EMAIL] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[UserFields.EMAIL]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold mb-1 block">
                        User Type
                      </label>
                      <Multiselect
                        options={userTypeOptions}
                        displayValue="label"
                        onSelect={(selectedList) =>
                          setFieldValue(
                            UserFields.USER_TYPE,
                            selectedList?.map((item) => item.value)
                          )
                        }
                        onRemove={(selectedList) =>
                          setFieldValue(
                            UserFields.USER_TYPE,
                            selectedList?.map((item) => item.value)
                          )
                        }
                        selectedValues={userTypeOptions.filter((option) =>
                          values[UserFields.USER_TYPE].includes(option.value)
                        )}
                        showCheckbox
                        showArrow
                        placeholder="Select"
                        style={{
                          multiselectContainer: {
                            padding: "0px",
                            border: "2px solid #B0E0FF",
                            borderRadius: "12px",
                          },
                          searchBox: {
                            border: "none",
                            height: "44px",
                            overflowY: "scroll",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          },
                          chips: {
                            backgroundColor: "#B0E0FF",
                            color: "black",
                          },
                        }}
                      />
                      {touched[UserFields.USER_TYPE] &&
                        errors[UserFields.USER_TYPE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[UserFields.USER_TYPE]}
                          </div>
                        )}
                    </div>

                    {/* Conditionally render User Role and Access Level based on User Type */}
                    {values[UserFields.USER_TYPE].includes(orgUserId) && (
                      <>
                        <div className="w-full">
                          <label className="text-sm font-bold mb-1 block">
                            User Role
                          </label>
                          <Multiselect
                            options={rolesOptions}
                            displayValue="label"
                            onSelect={(selectedList) =>
                              setFieldValue(
                                UserFields.ROLE,
                                selectedList?.map((item) => item.value)
                              )
                            }
                            onRemove={(selectedList) =>
                              setFieldValue(
                                UserFields.ROLE,
                                selectedList?.map((item) => item.value)
                              )
                            }
                            selectedValues={rolesOptions.filter((option) =>
                              values[UserFields.ROLE].includes(option.value)
                            )}
                            showCheckbox
                            showArrow
                            placeholder="Select Roles"
                            style={{
                              multiselectContainer: {
                                padding: "0px",
                                border: "2px solid #B0E0FF",
                                borderRadius: "12px",
                              },
                              searchBox: {
                                border: "none",
                                height: "44px",
                                overflowY: "scroll",
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                              },
                              chips: {
                                backgroundColor: "#B0E0FF",
                                color: "black",
                              },
                            }}
                          />
                          {touched[UserFields.ROLE] &&
                            errors[UserFields.ROLE] && (
                              <div className="mt-1 text-xs text-red-500">
                                {errors[UserFields.ROLE]}
                              </div>
                            )}
                        </div>

                        <div className="w-full">
                          <label className="text-sm font-bold">
                            Access Level{" "}
                          </label>
                          <div className="grid grid-cols-12 gap-4 mt-1 w-full items-end">
                            <div className="col-span-5">
                              <label className="text-xs font-bold">
                                Access
                              </label>
                            </div>
                            <div className="col-span-6">
                              <label className="text-xs font-bold">
                                Locale
                              </label>
                            </div>
                          </div>

                          <FieldArray
                            name="access_level"
                            render={(arrayHelpers) => (
                              <>
                                {values.access_level?.map((row, index) => (
                                  <div
                                    className="grid grid-cols-12 gap-4 my-1 w-full items-start"
                                    key={row.id}
                                  >
                                    <div className="col-span-5">
                                      <SelectDropDown
                                        options={accessLevelOptions}
                                        placeholder="Select"
                                        value={
                                          accessLevelOptions?.find(
                                            (item) =>
                                              item?.value ===
                                              values?.access_level[index]
                                                ?.access_level_id
                                          ) || null
                                        }
                                        onChange={(selectedOption) => {
                                          const selectedAccess =
                                            selectedOption?.value || "";

                                          setFieldValue(
                                            `access_level[${index}].access_level_id`,
                                            selectedAccess
                                          );
                                          setFieldValue(
                                            `access_level[${index}].locale`,
                                            []
                                          );
                                        }}
                                        touched={touched}
                                        errors={errors}
                                        isSearchable={true}
                                        isClearable={false}
                                      />
                                    </div>
                                    <div className="col-span-6">
                                      {row?.access_level_id ===
                                      accessLevelIds?.Pincode ? (
                                        <>
                                          <MultipleTagsInput
                                            name={`access_level[${index}].locale`}
                                            placeHolder="Enter Locale"
                                            value={
                                              values.access_level[index]
                                                ?.locale || []
                                            }
                                            onChange={(newLocales) =>
                                              setFieldValue(
                                                `access_level[${index}].locale`,
                                                newLocales
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        <Multiselect
                                          groupBy="group"
                                          options={getLocaleOptions(
                                            row?.access_level_id
                                          )}
                                          displayValue="label"
                                          onSelect={(selectedList) =>
                                            setFieldValue(
                                              `access_level[${index}].locale`,
                                              selectedList?.map(
                                                (item) => item.value
                                              )
                                            )
                                          }
                                          onRemove={(selectedList) =>
                                            setFieldValue(
                                              `access_level[${index}].locale`,
                                              selectedList?.map(
                                                (item) => item.value
                                              )
                                            )
                                          }
                                          selectedValues={getLocaleOptions(
                                            row?.access_level_id
                                          ).filter((option) =>
                                            row.locale.includes(option.value)
                                          )}
                                          showCheckbox
                                          // showArrow
                                          style={{
                                            multiselectContainer: {
                                              padding: "0px",
                                              border: "2px solid #B0E0FF",
                                              borderRadius: "12px",
                                            },
                                            searchBox: {
                                              border: "none",
                                              // height: "44px",
                                              overflowY: "scroll",
                                              scrollbarWidth: "none",
                                              msOverflowStyle: "none",
                                            },

                                            chips: {
                                              backgroundColor: "#B0E0FF",
                                              color: "black",
                                            },
                                          }}
                                        />
                                      )}
                                    </div>
                                    {values.access_level.length > 1 && (
                                      <div
                                        className="flex justify-start items-center col-span-1 cursor-pointer h-[44px]"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <Delete width={24} height={24} />
                                      </div>
                                    )}
                                  </div>
                                ))}
                                <button
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      access_level_id: "",
                                      locale: [],
                                    })
                                  }
                                >
                                  Add Row
                                </button>
                              </>
                            )}
                          />
                        </div>
                      </>
                    )}
                    <div className="w-full">
                      <label className="text-sm font-bold">User Status</label>
                      <div className="flex items-center space-x-4">
                        <Switch
                          ripple={false}
                          checked={userStatus}
                          onChange={() => {
                            setUserStatus(!userStatus);
                            setFieldValue(UserFields.IS_ACTIVE, !userStatus);
                          }}
                          value={values[UserFields.IS_ACTIVE]}
                          className="h-full w-full checked:bg-[#B0E0FF]"
                          containerProps={{
                            className: "w-8 h-4",
                          }}
                          circleProps={{
                            className:
                              "before:hidden left-1 border-none w-[12px] h-[12px]",
                          }}
                        />
                        <span>{userStatus ? "Active" : "Inactive"}</span>
                      </div>
                    </div>

                    <div className="w-full flex justify-end space-x-4 h-[48px]">
                      <button
                        className="bg-black text-white px-4 text-lg py-2 font-bold rounded-lg min-w-[32%] h-full"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setDataFromSSS(false);
                          resetForm();
                          setInitialObject(initialValues);
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="text-white px-4 py-2 rounded-md bg-WildWatermelon-button w-[32%] h-full font-bold"
                        type="submit"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export { AddUserModel };
