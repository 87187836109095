/**
 * Loader Message Component
 * @format
 */

import React from "react";
import { Oval } from "react-loader-spinner";

function Loader() {
  return (
    <div className="w-fit ">
      <Oval
        visible={true}
        height="50"
        width="50"
        color="#b0e0ff"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
        secondaryColor="e0e7ff"
      />
    </div>
  );
}

export { Loader };
