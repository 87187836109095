/**
 * No record Message Component
 * @format
 */

import React from "react";
import { Images } from "../constants";

function NoRecord(props) {
  const { title_1, title_2 } = props;
  return (
    <div className="px-4 text-center">
      <img
        src={Images.noRecord}
        alt="Logo"
        className="xl:max-w-[25rem] mx-auto max-w-[15rem]"
      />
      <div className="xl:text-[1.4rem] text-lg font-bold mt-5">{title_1}</div>
      <div className="xl:text-[1.15rem] text-base">{title_2}</div>
    </div>
  );
}

export { NoRecord };
