/**
 * Common selectors
 * @format
 */

const common = (state) => state.common;

export const selectLoader = (state) => common(state).loader;
export const selectUser = (state) => common(state).user;
export const selectActiveSection = (state) => common(state).activeSection;
export const selectAuthToken = (state) => common(state).authToken;
export const selectDistrict = (state) => common(state).district;
export const selectState = (state) => common(state).state;
export const selectUserType = (state) => common(state).userType;
export const selectStatus = (state) => common(state).status;
export const selectStages = (state) => common(state).stages;
export const selectCampTypes = (state) => common(state).campTypes;
export const selectUserProfile = (state) => common(state).userProfileData;
export const selectUserAllPermissions = (state) =>
  common(state).userAllPermissions;
export const selectCampAllStages = (state) => common(state).allCampStages;
export const selectCampAllStepTypess = (state) =>
  common(state).allCampStepTypes;
export const selectDistrictWithBeneficiaryCounts = (state) =>
  common(state).districtWithBeneficiaryCounts;
export const selectStateWithBeneficiaryCounts = (state) =>
  common(state).stateWithBeneficiaryCounts;
export const selectAllUserOptions = (state) => common(state).allUserOptions;
export const selectDistrictByState = (state) => common(state).districtByState;
export const selectFullScreenLoading = (state) =>
  common(state).fullScreenLoading;
