/**
 * Camp OverView Selector
 * @format
 */

const campOverviewManagement = (state) => state.campOverviewManagementReducer;

export const SelectAllCurrentCampsList = (state) =>
  campOverviewManagement(state).allCurrentCampsList;

export const SelectActiveCurrentCamp = (state) =>
  campOverviewManagement(state).activeCurrentCamp;

export const SelectActiveCampCounter = (state) =>
  campOverviewManagement(state).activeCampCounter;

export const SelectActiveCampStep = (state) =>
  campOverviewManagement(state).activeCampStep;

export const SelectAllCountCounterDataList = (state) =>
  campOverviewManagement(state).allCountCounterDataList;

export const SelectAllCountStepDataList = (state) =>
  campOverviewManagement(state).allCountStepDataList;

export const SelectAllBeneficiaryListOfCamp = (state) =>
  campOverviewManagement(state).allBeneficiaryListOfCamp;

export const SelectViewSingleBeneficiaryDetail = (state) =>
  campOverviewManagement(state).viewSingleBeneficiaryDetail;

export const SelectAllCounterDataList = (state) =>
  campOverviewManagement(state).allCounterDataList;

export const SelectAllBeneficiaryListOfStepOfCamp = (state) =>
  campOverviewManagement(state).allBeneficiaryListOfStepOfCamp;

export const SelectAllBeneficiaryListOfCounterOfStep = (state) =>
  campOverviewManagement(state).allBeneficiaryListOfCounterOfStep;

export const SelectLoaderBeneficiaryCamp = (state) =>
  campOverviewManagement(state).loaderBeneficiaryCamp;

export const SelectLoaderBeneficiaryCampStep = (state) =>
  campOverviewManagement(state).loaderBeneficiaryCampStep;

export const SelectLoaderBeneficiaryCampCounter = (state) =>
  campOverviewManagement(state).loaderBeneficiaryCampCounter;
