/**
 * Beneficiary all Details Components
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { CheckFill } from "../../../assets/icons/CheckFill";
import BeneficiaryDetail from "./beneficiaryDetail";
import BeneficiaryVerificationPart from "./beneficiaryVerificationForm";
import { constants, manipulateDateAndTime } from "../../../utils";
import PersonalInformation from "./personalInformation";
import { selectBeneficiaryProcessDetail } from "../selector";
import { useDispatch, useSelector } from "react-redux";
import { getBeneficiaryProcessDetails } from "../slice";
import {
  getAllStages,
  getAllStatus,
  selectStages,
  selectStatus,
  selectUserAllPermissions,
} from "../../common";
import BeneficiaryDetailWithCurrentStatus from "./beneficiaryDetailWithCurrentStatus";

function BeneficiaryAllDetail(props) {
  const dispatch = useDispatch();
  const [dateTime, setDateTime] = useState({});
  const scrollableRef = useRef(null);
  const userPermissions = useSelector(selectUserAllPermissions) || {};
  const { beneficiaryDetails, campData, beneficiaryJourney } =
    useSelector(selectBeneficiaryProcessDetail) || {};

  const status =
    useSelector(selectStatus).reduce((acc, status) => {
      acc[status.status_name] = status._id;
      return acc;
    }, {}) || {};
  const stageIds =
    useSelector(selectStages).reduce((acc, stage) => {
      acc[stage.beneficiary_stage_name] = stage._id;
      return acc;
    }, {}) || {};

  const beneficiary = props.singleBeneficiary;

  const getBeneficiaryProcessDetailByBeneficiaryId = (id) => {
    dispatch(
      getBeneficiaryProcessDetails({
        id,
        onSuccess: (props) => {
        },
      })
    );
  };

  const getStatusData = () => {
    dispatch(
      getAllStatus({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  const getStagesData = () => {
    dispatch(
      getAllStages({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    createdBy: verification_data?.created_by?.name,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: verification_data?.createdAt,
  };

  useEffect(() => {
    scrollableRef.current.scrollTo(0, 0);

    // Data and time manipulate
    const result = manipulateDateAndTime(beneficiary?.createdAt);
    setDateTime(result);

    // get All Status Api Call
    getStatusData();
    // get All Stages Api Call
    getStagesData();

    // verify and rejected beneficiary api call
    if (beneficiary?.status == "Rejected") {
      getBeneficiaryProcessDetailByBeneficiaryId(beneficiary?._id);
    }
  }, [beneficiary]);

  return (
    <>
      <div className="container h-full self-end w-full pr-4">
        <div
          className="h-full overflow-y-scroll scrollbar-hide flex flex-col justify-between  rounded-xl"
          ref={scrollableRef}
        >
          <div className="container flex flex-col justify-between flex-auto bg-white px-6 py-4  divide-y-2 divide-[#c1c1c1] divide-dashed">
            {/* Beneficiary Detail */}
            {props.bySearch ? (
              <BeneficiaryDetailWithCurrentStatus userData={beneficiary} />
            ) : (
              <BeneficiaryDetail userData={beneficiary} />
            )}

            {/* Registration */}
            <div className="container p-0 flex flex-col justify-between">
              {beneficiary && (
                <div className="flex flex-row my-4 mx-1 ">
                  <div className="">
                    <CheckFill width={32} height={32} />
                  </div>
                  <div className="flex flex-col space-y-2 px-3 py-1 text-sm font-inter justify-center">
                    <div className="flex-1 text-warning-success font-medium">
                      {constants.registration}
                    </div>
                    <div className="flex-1 text-gray-tone-400 ">
                      {`${constants.complete_on} ${dateTime.date}; ${dateTime.time}`}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Personal Details and Address */}
            <div className="container ">
              <PersonalInformation singleBeneficiary={beneficiary} />
            </div>
          </div>

          {/* Verification Part */}
          {userPermissions?.Verify_Beneficiary === true && (
            <BeneficiaryVerificationPart
              userData={beneficiary}
              list={props?.data}
              status={status}
              userVerificationData={latest_data}
              stageIds={stageIds}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default BeneficiaryAllDetail;
