/**
 * Utils
 * all veriable declare here
 * @format
 */

exports.constants = {
  SSSDS: "Sri Sathya Sai Divyangjan Seva",

  // Dashboard

  // Beneficiary Management
  registration: "Registration",
  registration_no: "Registration No:",
  complete_on: "Completed on",
  beneficiary_list: "Beneficiary List",
  select_beneficiary: "Select beneficiary to view beneficiaryData's details",
  Communication_history: "Communication History",
  age: "Age:",
  disability_type: "Disability Type:",
};
