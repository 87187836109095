/**
 * Camp Center Management Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCampCentersList: {},
  campCenter: {},
  allStateWithCampCenterCount: [],
  CampCenterCurrentPage: 1,
  CampCenterRespPerPage: 10,
  CampCenterStateId: "",
  campCenterSearchQuery: "",
};

const campCenterManagementSlice = createSlice({
  name: "campCenterManagement",
  initialState,
  reducers: {
    setAllCampCenterList(state, action) {
      state.allCampCentersList = action.payload;
    },
    setCampCenter(state, action) {
      state.campCenter = action.payload;
    },
    setCampCenterCurrentPage(state, action) {
      state.CampCenterCurrentPage = action.payload;
    },
    setCampCenterRespPerPage(state, action) {
      state.CampCenterRespPerPage = action.payload;
    },
    setAllStateWithCampCenterCount(state, action) {
      state.allStateWithCampCenterCount = action.payload;
    },
    setCampCenterStateId(state, action) {
      state.CampCenterStateId = action.payload;
    },
    setCampCenterSearchQuery(state, action) {
      state.campCenterSearchQuery = action.payload;
    },
  },
});

// Reducer
export const campCenterManagementReducer = campCenterManagementSlice.reducer;

// Actions
export const {
  setAllCampCenterList,
  setCampCenter,
  setCampCenterCurrentPage,
  setCampCenterRespPerPage,
  setAllStateWithCampCenterCount,
  setCampCenterStateId,
  setCampCenterSearchQuery,
} = campCenterManagementSlice.actions;

export const allCampCenterList = createAction("ALLCAMPCENTERLIST");
export const createCampCenter = createAction("CREATENEWCAMPCENTER");
export const updateCampCenterById = createAction("UPDATECAMPCENTERBYID");
export const getCampCenterById = createAction("GETCAMPCENTERBYID");
export const deleteCampCenter = createAction("DELETECAMPCENTER");
export const updateCampCenterStatusById = createAction(
  "UPDATECAMPCENTERSTATUSBYID"
);
export const getAllStateWithCampCenterCount = createAction(
  "ALLSTATUSWITHCAMPCENTERCOUNT"
);
