/**
 * Access Level Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { createLoader } from "../common";
import { endPoints, httpRequest } from "../../services";
import {
  allAccessLevelList,
  createAccessLevel,
  deleteAccessLevel,
  getAccessLevelById,
  setAllAccessLevelDataList,
  updateAccessLevelById,
} from "./slice";

/**
 * Get all Access Level list
 * @param {*}
 */
function* allAccessLevelListSaga(action) {
  try {
    const { search, page, perPage, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allAccessLevelsList, {
      search,
      page,
      responsePerPage: perPage,
    });
    yield onSuccess({ message: response?.message });
    yield put(setAllAccessLevelDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Create Access Level with fields
 * @param {*} *fields
 */
function* createAccessLevelSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { name } = values;
    const response = yield httpRequest.post(endPoints.createAccessLevel, {
      name,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update Access Level By Id
 * @param {*} *fields
 */
function* updateAccessLevelByIdSaga(action) {
  try {
    const { updatedValues, onSuccess } = action.payload;
    const { id, name, active } = updatedValues;
    const response = yield httpRequest.put(endPoints.updateAccessLevelById, {
      id,
      name,
      active: active,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Access Level By Id
 * @param {*} Id
 */
function* getAccessLevelByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getAccessLevelById}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Delete Access Level
 * @param {*} Id
 */
function* deleteAccessLevelSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deleteAccessLevel}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* accessLevelManagementSaga() {
  yield takeLatest(allAccessLevelList, allAccessLevelListSaga);
  yield takeLatest(createAccessLevel, createAccessLevelSaga);
  yield takeLatest(updateAccessLevelById, updateAccessLevelByIdSaga);
  yield takeLatest(getAccessLevelById, getAccessLevelByIdSaga);
  yield takeLatest(deleteAccessLevel, deleteAccessLevelSaga);
}
