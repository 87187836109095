import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Date } from "../../../assets/icons/Date";
import { Clock } from "../../../assets/icons/Clock";
import { MultipleUser } from "../../../assets/icons/MultipleUser";
import { RightArrow } from "../../../assets/icons/RightArrow";
import { LeftArrow } from "../../../assets/icons/LeftArrow";
import { Images } from "../../../constants";
import { formatDate, todayDate } from "../../../utils";
import { LocationMap } from "../../../assets/icons/LocationMap";
import CustomButton from "../../../components/customButtion";
import {
  getStepAndCounterOfUser,
  updateToggleForAvailableUnavailable,
} from "../../camp_management/slice";
import { useDispatch } from "react-redux";
import ViewMapLink from "../../../components/viewMapLink";
import { Loader } from "../../../components";

const UpcomingCampsCard = (props) => {
  const { myUpcomingCampData } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentCampIndex, setCurrentCampIndex] = useState(0);
  const [active, setActive] = useState("today");
  const [activeCampName, setActiveCampName] = useState("My Today's Camps");
  const [upcoming_camps, setUpcoming_camps] = useState([]);
  const [loading, setLoading] = useState(false);

  const upcomingTotalCamps = upcoming_camps?.length || [];

  // Handle Next Camp with loading state
  const handleNextCamp = () => {
    setLoading(true);
    setTimeout(() => {
      setCurrentCampIndex((prevIndex) =>
        prevIndex < upcomingTotalCamps - 1 ? prevIndex + 1 : prevIndex
      );
      setLoading(false);
    }, 200);
  };

  // Handle Previous Camp with loading state
  const handlePrevCamp = () => {
    setLoading(true);
    setTimeout(() => {
      setCurrentCampIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      setLoading(false);
    }, 200);
  };

  useEffect(() => {
    if (myUpcomingCampData) {
      setUpcoming_camps(myUpcomingCampData?.currentCamps || []);
    }
  }, [myUpcomingCampData]);

  const currentCamp = upcoming_camps[currentCampIndex];

  return (
    <div className="w-full bg-white rounded-xl p-3 xl:p-4 border-2 border-gray-100">
      <div className="flex flex-row space-x-4 justify-start items-center mb-4">
        <span
          className={`text-xs font-bold cursor-pointer ${
            active === "today"
              ? "underline decoration-blue-500 decoration-[1.5px] underline-offset-4"
              : ""
          }`}
          onClick={() => {
            setActive("today");
            setActiveCampName("My Today's Camps");
            setUpcoming_camps(myUpcomingCampData?.currentCamps || []);
          }}
        >
          My Today's Camps
        </span>
        <span
          className={`text-xs font-bold cursor-pointer ${
            active === "upcoming"
              ? "underline decoration-blue-500 decoration-[1.5px] underline-offset-4"
              : ""
          }`}
          onClick={() => {
            setActive("upcoming");
            setActiveCampName("My Upcoming Camps");
            setUpcoming_camps(myUpcomingCampData?.upcomingCamps || []);
          }}
        >
          My Upcoming Camps
        </span>
      </div>

      <div className="flex flex-row md:flex-wrap justify-between items-center mb-0 xl:mb-2">
        <h3 className="text-base xl:text-xl font-bold">{activeCampName}</h3>
        {upcomingTotalCamps > 0 ? (
          <div className="text-sm whitespace-nowrap capitalize">
            <button
              onClick={handlePrevCamp}
              disabled={currentCampIndex === 0}
              className=" font-bold"
            >
              <LeftArrow width={6} height={11} />
            </button>
            <span className="px-2 text-sm">{`${
              currentCampIndex + 1
            } / ${upcomingTotalCamps}`}</span>
            <button
              onClick={handleNextCamp}
              disabled={currentCampIndex === upcomingTotalCamps - 1}
              className=" font-bold"
            >
              <RightArrow width={6} height={11} />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-60">
          <Loader />
        </div>
      ) : (
        <div>
          {upcomingTotalCamps > 0 ? (
            <>
              <div>
                <div className="py-0 border-b ">
                  <h4 className="text-base xl:text-lg font-semibold mb-2">
                    {/* {currentCamp?.scheduled_camp_id?.camp_type_id?.camp_type_name} */}
                    {currentCamp?.name}
                  </h4>
                </div>
                <div className="flex flex-row justify-between items-center text-sm py-1 xl:py-3 font-semibold capitalize">
                  {
                    currentCamp?.scheduled_camp_id?.camp_location_id
                      ?.camp_location_name
                  }
                  <ViewMapLink
                    geo_coordinates={
                      currentCamp?.scheduled_camp_id?.camp_location_id
                        ?.geo_coordinates
                    }
                  />
                </div>
                <div className="flex flex-row space-x-2 items-center">
                  <LocationMap height={18} width={15} fillColor={"#000000"} />
                  <p className="text-sm capitalize">
                    {" "}
                    {
                      currentCamp?.scheduled_camp_id?.camp_location_id
                        ?.address_1
                    }
                    , Dist-
                    {
                      currentCamp?.scheduled_camp_id?.camp_location_id?.district
                        ?.district_name
                    }
                    .
                  </p>
                </div>
                <div className="flex flex-row flex-wrap justify-start items-center bg-gray-100 p-0 rounded-lg my-3 xl:my-4 text-sm">
                  <span className="p-2 pe-0 flex-1">
                    Step:{" "}
                    <strong>
                      {currentCamp?.camp_step_id?.camp_step_display_name}
                    </strong>
                  </span>
                  <span className="p-2 flex-1 ps-0">
                    Counter No: <strong>{currentCamp?.counter_number}</strong>
                  </span>
                </div>
                <div className="text-sm">
                  <div className="flex items-center mb-2">
                    <span role="img" aria-label="calendar" className="mr-2">
                      <Date width={18} height={18} />
                    </span>
                    <span className="text-black font-medium">
                      {formatDate(currentCamp?.scheduled_camp_id?.from_date)}
                    </span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span role="img" aria-label="clock" className="mr-2">
                      <Clock width={16} height={16} />
                    </span>
                    <span className="text-black font-medium">
                      {currentCamp?.scheduled_camp_id?.from_time}-
                      {currentCamp?.scheduled_camp_id?.to_time}
                    </span>
                  </div>
                  <div className="flex items-center mb-3 xl:mb-4">
                    <span role="img" aria-label="users" className="mr-2">
                      <MultipleUser width={18} height={20} />
                    </span>
                    <span className="text-black font-medium">
                      {currentCamp?.beneficiaryCount} Beneficiaries
                    </span>
                  </div>
                  <div className="text-gray-tone-400">
                    <strong className="text-sm font-bold underline">
                      Contact Person
                    </strong>
                    <p>
                      {
                        currentCamp?.scheduled_camp_id?.camp_location_id
                          ?.contact_person_name
                      }
                      ,{" "}
                      <span className="underline text-sm font-bold">
                        {
                          currentCamp?.scheduled_camp_id?.camp_location_id
                            ?.contact_person_phone
                        }
                      </span>
                    </p>
                  </div>
                </div>{" "}
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-start items-center space-y-6 ">
              <img
                src={Images?.upcomingCampImage}
                alt="Upcoming Camp"
                className="w-full h-full "
              />
              <span className="text-center text-base font-medium">
                There isn’t any Upcoming Camp(s) for you!
              </span>
            </div>
          )}
          {upcomingTotalCamps > 0 && (
            <div>
              {formatDate(currentCamp?.scheduled_camp_id?.from_date) ===
              formatDate(todayDate()) ? (
                <CustomButton
                  name="START"
                  type="submit"
                  loading={false}
                  disabled={false}
                  onClick={() => {
                    dispatch(
                      getStepAndCounterOfUser({
                        scheduled_camp_id: currentCamp?.scheduled_camp_id?._id,
                        onSuccess: (props) => {
                          const { data } = props;
                          // if (!data?.is_active) {
                          dispatch(
                            updateToggleForAvailableUnavailable({
                              id: data?.counter_id,
                              onSuccess: (props) => {
                                navigate(
                                  `/myCamp/${currentCamp?.scheduled_camp_id?._id}`,
                                  {
                                    replace: true,
                                  }
                                );
                              },
                            })
                          );
                          // }
                        },
                      })
                    );
                  }}
                  className={
                    "w-full h-fit bg-blue-500 text-white font-semibold mt-4 xl:mt-6 text-sm  xl:text-base"
                  }
                />
              ) : (
                <CustomButton
                  name="SCHEDULE CAMP"
                  type="submit"
                  loading={false}
                  disabled={false}
                  onClick={() => {
                    navigate(`/scheduleCampsList`, {
                      replace: true,
                    });
                  }}
                  className={
                    "w-full h-fit bg-blue-500 text-white font-semibold mt-4 xl:mt-6 text-sm  xl:text-base"
                  }
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UpcomingCampsCard;
