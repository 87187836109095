/**
 * Login Screen
 * @format
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import OTPInput from "react-otp-input";

import { Images, MainRoutes, UserFields } from "../../../constants";
import { sendOTP, login, reSendOTP } from "../slice";
import { toast } from "react-toastify";
import { Edit } from "../../../assets/icons/Edit";
import { getUserProfile } from "../../common";

const validationSchema = Yup.object().shape({
  [UserFields.MOBILE_NUMBER]: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
});

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    [UserFields.MOBILE_NUMBER]: "",
    [UserFields.OTP]: "",
  };

  const [otpSent, setOtpSent] = useState(false);
  const [timerActive, setTimerActive] = useState(false);
  const [resendTimer, setResendTimer] = useState(59);

  useEffect(() => {
    let interval = null;

    if (timerActive) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer === 1) {
            setTimerActive(false);
            clearInterval(interval);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timerActive]);

  const handleSendOTP = (values, actions) => {
    if (!otpSent) {
      dispatch(
        sendOTP({
          mobile_number: values[UserFields.MOBILE_NUMBER],
          onSuccess: (props) => {
            setOtpSent(true);
            setTimerActive(true);
            actions.setSubmitting(false);
            toast.success(props?.message);
          },
        })
      );
    }
  };

  const handleResendOTP = (mobile_number, setSubmitting) => {
    // Set isSubmitting to true when the function is called
    setSubmitting(true);

    dispatch(
      reSendOTP({
        mobile_number,
        onSuccess: (props) => {
          setTimerActive(true);
          setResendTimer(9);
          toast.success(props?.message);
          setSubmitting(false); // Set isSubmitting to false after success
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to resend OTP. Please try again.");
          setSubmitting(false); // Set isSubmitting to false after an error
        },
      })
    );
  };

  return (
    <section className="bg-cover bg-login-bg flex justify-center min-h-screen xl:py-[1vw] py-2 relative md:items-center">
      {/* Side Circle Design Images */}
      <div className="absolute top-[10px] right-0">
        <img src={Images.loginDesignCircle1} alt="" className="" />
      </div>
      <div className="absolute bottom-0 left-0">
        <img src={Images.loginDesignCircle2} alt="" className="" />
      </div>

      {/* Main  container */}
      <div className="h-max mt-[50px] relative container rounded-[2rem]  xl:right-7 right-0  bg-customBlue-mainBackground p-0 flex shadow-login-3xl xl:min-h[48rem] 3xl:max-w-[50rem] xl:max-w-[48rem] lg:max-w-[38rem] md:max-w-[60%] max-w-[80%]">
        {/* SSS Baba Image in Left side*/}
        <div className="4xl:w-[23.44rem] xl:w-[18rem] w-[12.5rem] h-full m-0 z-10">
          <div className="h-full w-full relative">
            <div className=" relative z-10 4xl:w-[23.44rem] xl:w-[18rem] w-full ">
              <img
                src={Images.SathyaSai}
                alt="Splash Screen"
                className="w-full h-full rounded-tl-[35px] "
                style={{ "object-fit": "contain" }}
              />
            </div>

            <div className="w-full flex flex-col justify-between z-10 4xl:min-h-[25rem] 2xl:min-h-[22rem] xl:min-h-[18rem] md:min-h-[19rem] min-h-[13.6rem] ">
              <img
                src={Images.login2}
                alt="Splash Screen"
                className="absolute w-full rounded-bl-[35px] bottom-0 z-10 4xl:min-h-[33rem] 2xl:min-h-[31.5rem] xl:min-h-[15.5rem] md:min-h-[25rem] min-h-[18rem] "
              />
              <div className="relative -mt-[15%] z-30 ">
                <img
                  src={Images.LogoSSS}
                  alt="Logo"
                  className="xl:w-[69px] w-[60px] m-auto "
                />
              </div>

              <img
                src={Images.SSSLoginCenterText}
                alt="Splash Screen"
                className="w-[65%] m-auto z-30"
              />
              <div className="inset-0 flex justify-center items-end z-20 relative">
                <img
                  src={Images.PeopleGroup}
                  alt="Splash Screen"
                  className="w-full rounded-bl-[35px]"
                />
                <img
                  src={Images.SSSName}
                  alt="Splash Screen"
                  className="w-[60%] absolute bottom-11 z-30"
                />
              </div>
            </div>
          </div>
        </div>

        {/* login criteria in Right Side */}
        <div className="relative justify-start items-center m-0 rounded-r-[1.5rem] md:rounded-[1.5rem] right-form flex-1">
          {/* <div className="flex flex-col w-[61%] relative justify-start items-center h-full m-0 rounded-r-[1.5rem]"> */}
          {/* Main  container bg Image */}
          <div className="absolute w-full z-[0] md:overflow-hidden abs-img">
            <img
              src={Images.bgImageLogin}
              alt="Vector"
              className="border-none h-full w-full object-cover 4xl:max-h-[51.25rem] 2xl:max-h-[41.95rem] xl:max-h-[38.05rem] lg:max-h-[33rem] max-h-[30rem] rounded-r-[1.5rem]"
            />
          </div>
          <div className="relative">
            <div className="">
              <div className=" relative xl:-top-[52px] -top-[2rem] flex items-center flex-col 2xl:gap-[34px] xl:gap-6 gap-[1rem] -ml-[30px] md:ml-[0]">
                <img
                  src={Images.LogoLogin}
                  alt="Logo"
                  className="2xl:w-[7.5rem]   sm:w-[5rem] w-[4rem]"
                />
                <p className="text-center text-themeRoyalBlue 2xl:text-4xl xl:text-2xl text-xl font-bold">
                  Sri Sathya Sai <br />
                  Divyangjan Seva{" "}
                </p>
              </div>
            </div>

            <div className="container flex flex-col  p-0 xl:pb-[40px] items-center">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                  try {
                    if (!otpSent) {
                      await handleSendOTP(values, actions);
                    } else {
                      await dispatch(
                        login({
                          mobile_number: values[UserFields.MOBILE_NUMBER],
                          otp: values[UserFields.OTP],
                          onSuccess: (props) => {
                            toast.success(props?.message);
                            navigate(MainRoutes.DashboardScreen, {
                              replace: true,
                            });
                            dispatch(getUserProfile());
                          },
                        })
                      );
                    }
                  } catch (error) {
                    console.error(error);
                    // Show an error toast or handle the error appropriately
                  } finally {
                    // Reset the form submission state
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  setSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className=""
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                  >
                    <div className="xl:w-[399px] w-[300px]  mx-auto flex flex-col pt[40px] 2xl:gap-[30px] md:gap-4 gap-2  4xl:pt-[50px] lg:p-[20px]   maxlg:p-0">
                      {/* Mobile Number fields */}
                      <div className="w-full">
                        <label
                          htmlFor="mobile"
                          className="mb-1 font-bold flex  xl:text-2xl text-lg "
                        >
                          Mobile{" "}
                          <span className="font-medium ml-1">Number</span>
                        </label>
                        <div className="text-sm">
                          <span className="font-DM text-themeGrey">
                            Enter Your 10 Digit
                          </span>{" "}
                          <span className="font-medium">Number</span>
                        </div>

                        <div className="relative mt-4 maxlg:mt-1">
                          <input
                            autoComplete="off"
                            type="tel"
                            name={UserFields.MOBILE_NUMBER}
                            id={UserFields.MOBILE_NUMBER}
                            onChange={handleChange}
                            value={values[UserFields.MOBILE_NUMBER]}
                            maxLength={10}
                            // placeholder="Enter Your Mobile Number"
                            className="relative cursor-pointer border-2 border-customBlue-border  focus:outline-none font-DM font-semibold hover:border-[3px] hover:border-border px-3 2xl:py-3 py-2 rounded-xl 2xl:text-2xl text-base w-full 2xl:h-[56px]"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={otpSent}
                          />
                          {otpSent && (
                            <button
                              className=" cursor-pointer absolute top-[50%] right-[10px] z-[1]"
                              style={{ transform: "translate(0,-50%)" }}
                              onClick={() => {
                                setOtpSent(false);
                                setTimerActive(false);
                                setFieldValue(UserFields.OTP, "");
                              }}
                            >
                              <Edit width={20} height={20} />
                            </button>
                          )}
                        </div>
                        {errors[UserFields.MOBILE_NUMBER] &&
                          touched[UserFields.MOBILE_NUMBER] && (
                            <div className="text-WildWatermelon-button text-xs mt-1">
                              {errors[UserFields.MOBILE_NUMBER]}
                            </div>
                          )}
                      </div>
                      {/* Otp fields */}
                      {otpSent && (
                        <>
                          <div>
                            <label
                              htmlFor="otp"
                              className="flex text-sm font-[600] mb-[13px] maxlg:mb-1"
                            >
                              Enter OTP
                            </label>
                            <div className="flex justify-between mb-1 otp-input">
                              <OTPInput
                                containerStyle={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "-webkit-fill-available",
                                  gap: "0.6rem",
                                }}
                                //   width: "78px",
                                //   maxHeight: "56px",
                                //   borderRadius: "10px",
                                //   borderWidth: "2px",
                                //   borderColor: "#B0E0FF",
                                //   textAlign: "center",
                                //   fontSize: "24px",
                                //   fontWeight: "bold",
                                //   padding: "12px 0 12px 0",
                                //   cursor: "pointer",
                                // },
                                inputStyle="relative cursor-pointer border-2 border-customBlue-border  focus:outline-none font-DM font-semibold hover:border-[3px] hover:border-border px-3 2xl:py-3 py-2 rounded-xl 2xl:text-2xl text-base w-full 2xl:h-[56px] flex-1"
                                value={values[UserFields.OTP]}
                                onChange={(otp) => {
                                  handleChange({
                                    target: {
                                      name: UserFields.OTP,
                                      value: otp,
                                    },
                                  });
                                  setFieldValue(UserFields.OTP, otp);
                                  // if (otp.length === 4) {
                                  //   handleSubmit();
                                  // }
                                }}
                                inputType="tel"
                                numInputs={4}
                                renderInput={(props) => <input {...props} />}
                              />
                              {errors[UserFields.OTP] &&
                                touched[UserFields.OTP] && (
                                  <div className="text-WildWatermelon-button text-xs mt-1">
                                    {errors[UserFields.OTP]}
                                  </div>
                                )}
                            </div>
                            {timerActive ? (
                              <div className="flex justify-end mt-0 mr-1 text-sm font-semibold">
                                <p className="text-customOrange-text">
                                  Resend in{" "}
                                  <span className="text-[rgb(0,0,0)] text-xs font-normal font-DM">
                                    00:{resendTimer}
                                  </span>{" "}
                                  sec
                                </p>
                              </div>
                            ) : (
                              <div className="flex text-sm font-semibold justify-end mt-0 mr-1">
                                <button
                                  type="button"
                                  className={`text-customOrange-text ${
                                    timerActive
                                      ? "opacity-50 cursor-not-allowed"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    handleResendOTP(
                                      values[UserFields.MOBILE_NUMBER],
                                      setSubmitting
                                    );
                                    setFieldValue(UserFields.OTP, "");
                                  }}
                                  disabled={isSubmitting || timerActive}
                                >
                                  Resend OTP
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {/* Button */}
                      <div className="flex flex-col justify-center items-center">
                        {/* <button
                          type="submit"
                          // disabled={isSubmitting || !values[UserFields.OTP]}
                          className={`min-w-[330px] md:min-w-[100%] py-[13px] bg-WildWatermelon-button text-white font-bold xl:text-lg  text-base px-4 rounded-xl border"
                          `}
                        >
                          {otpSent ? "Verify OTP" : "Send OTP"}
                        </button> */}
                        {otpSent ? (
                          <button
                            type="submit"
                            disabled={isSubmitting || !values[UserFields.OTP]}
                            className={`min-w-[300px] md:min-w-[100%] xl:py-3 py-2 bg-WildWatermelon-button text-white font-bold xl:text-lg  text-base px-4 rounded-xl border"
                          ${
                            !values[UserFields.OTP]
                              ? "opacity-50 cursor-not-allowed"
                              : ""
                          }`}
                          >
                            Verify OTP
                          </button>
                        ) : (
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`min-w-[300px] md:min-w-[100%] xl:py-3 py-2 bg-WildWatermelon-button text-white font-bold xl:text-lg  text-base px-4 rounded-xl border"
                          ${
                            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                          >
                            Send OTP
                          </button>
                        )}
                        {/* <div className="font-semibold text-sm text-customBlue-callText mt-2 cursor-pointer">
                          For Support Call:{" "}
                          <a className="font-inter">1-800-9898-987</a>{" "}
                        </div> */}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { LoginScreen };
