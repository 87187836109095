/**
 * Fabrication List Screen
 * @format
 */

import React, { useEffect, useState } from "react";
import { HeaderScreen, Loader, Sidebar } from "../../../components";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { Clock } from "../../../assets/icons/Clock";
import { CalendarNew } from "../../../assets/icons/Calender";
import { ClockVarient } from "../../../assets/icons/ClockVarient";
import { InfoIconFilled } from "../../../assets/icons/InfoIcon";
import { CheckCircleGreen } from "../../../assets/icons/CheckCircleGreen";
import { NotAllowed } from "../../../assets/icons/NotAllowed";
import NavigationBar from "../../beneficiary_management/components/navigationBar";
import { useDispatch, useSelector } from "react-redux";
import { allFabricationCampsList, setFabricationSearchQuery } from "../slice";
import {
  selectallFabricationCampsDataList,
  selectFabricationCurrentPage,
  selectFabricationDataPerPage,
  selectFabricationNavStatus,
  selectFabricationSearchQuery,
} from "../selector";
import { formatDate } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { NoRecord } from "../../../components/noRecords";
import ViewMapLink from "../../../components/viewMapLink";
import Pagination from "../../../components/pagination";
import { FilterIcon } from "../../../assets/icons/FilterIcon";

function FabricationListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fabricationListLoader, setFabricationListLoader] = useState(false);

  const {
    filteredCamps: fabricationCamps = [],
    completedCount,
    inProgressCount,
    notYetStartedCount,
    totalCount,
    currentPage,
  } = useSelector(selectallFabricationCampsDataList) || {};

  const fabricationSearch = useSelector(selectFabricationSearchQuery) || "";
  const fabricationNavStatus = useSelector(selectFabricationNavStatus);
  const fabricationCurrentPage = useSelector(selectFabricationCurrentPage);
  const fabricationLimit = useSelector(selectFabricationDataPerPage);

  const getAllData = (search, filter, page, perPageLimit) => {
    setFabricationListLoader(true);
    dispatch(
      allFabricationCampsList({
        search,
        filter,
        page,
        perPageLimit,
        onSuccess: (props) => {
          setFabricationListLoader(false);
        },
        onFailure: (props) => {
          setFabricationListLoader(false);
        },
      })
    );
  };

  useEffect(() => {
    getAllData(fabricationSearch, fabricationNavStatus);
  }, [
    fabricationSearch,
    fabricationNavStatus,
    fabricationCurrentPage,
    fabricationLimit,
  ]);

  return (
    <div className="h-screen min-w-screen">
      <HeaderScreen />
      <div className="flex h-[calc(100vh-65px)]">
        <Sidebar />
        <div className="flex-col xl:p-4 p-2 h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto space-y-3">
          <div className="w-full max-w-full  mt-0 flex items-center ">
            <div className="flex flex-row items-center justify-between font-bold xl:text-2xl text-lg title max-w-[42%] min-w-[42%]">
              <div>Fabrication</div>
            </div>
          </div>
          <div className="">
            <NavigationBar
              statuses={[
                `Completed(${completedCount || 0})`,
                `In Progress(${inProgressCount || 0})`,
                `Not Yet Started(${notYetStartedCount || 0})`,
              ]}
              getData={getAllData}
              barName="fabricationScreen"
            />
          </div>
          <div className="container w-full ">
            <div className="grid grid-flow-col grid-cols-12 justify-between w-full items-center">
              {/* Search Bar */}
              <div className="flex justify-between space-x-2 h-[40px] col-span-6">
                <div className="relative w-full h-[40px] rounded-xl ">
                  <div className="absolute inset-y-0 start-0 flex items-center xl:ps-3 ps-2 pointer-events-none h-full ">
                    <SearchIcon width={24} height={24} />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full h-full font-inter xl:p-4 p-2 xl:ps-10 ps-10 xl:text-base md:text-sm text-base text-gray-tone-100 rounded-xl search-bar"
                    placeholder="Search by Camp Code"
                    onChange={(event) => {
                      dispatch(setFabricationSearchQuery(event.target.value));
                    }}
                  />
                </div>
                {/* <div className="border w-fit h-full bg-white rounded-lg border-[#E8E8E8] flex justify-center items-center px-2 cursor-pointer">
                  <div>
                    <FilterIcon width={20} height={20} />
                  </div>
                </div> */}
              </div>
              <div className="col-span-6">
                {/* <Pagination
                  name={"fabricationListScreen"}
                  totalNumber={totalCount}
                  dataPerPage={fabricationLimit}
                  data={getAllData}
                  currentPageApi={fabricationCurrentPage}
                  perPageOptions={[5, 10, 30, 50, 100]}
                  showFirstButton={true}
                  showLastButton={true}
                /> */}
              </div>
            </div>
          </div>
          {/* Camps Details list  */}
          {fabricationListLoader ? (
            <div className="flex items-center justify-center h-[75%]">
              <Loader />
            </div>
          ) : fabricationCamps?.length > 0 ? (
            fabricationCamps?.map((camp, campIndex) => (
              <>
                <div class="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-xl border-2 border-customBlue-border gap-3">
                  {/* <div className="flex align-items-center justify-between"> */}
                  <div class="flex justify-between w-full items-center flex-wrap">
                    <div className="flex flex-1 flex-wrap items-center gap-1">
                      <h3 class="font-bold">{`${camp?.camp_type_id?.camp_type_display_name}`}</h3>
                      <span class="text-gray-tone-divider">|</span>
                      <span className="text-xs text-gray-tone-100">
                        Held on :
                      </span>

                      <div class="flex lg:flex-wrap justify-between gap-2 items-center">
                        <span class="font-semibold flex gap-2 items-center">
                          <CalendarNew />
                          <span className="text-[#256CD0]">
                            {formatDate(camp?.from_date)}
                          </span>
                        </span>
                        <span class="font-semibold flex gap-2 items-center">
                          <Clock width={16} height={16} />
                          <span className="text-[#868686]">
                            {`${camp?.from_time} - ${camp?.to_time}`}
                          </span>
                        </span>
                      </div>
                    </div>
                    <span class="font-semibold">
                      Total Beneficiaries Screened:
                      <span class="text-[#FF5976] font-bold ps-1">
                        {camp?.beneficiaryCount?.total}
                      </span>
                    </span>
                  </div>
                  {/* </div> */}

                  <div class="flex flex-wrap w-full gap-2 text-sm">
                    <div class="border-2 border-gray-100 flex-1 p-2 rounded-lg flex flex-col space-y-2 ">
                      <div class="flex md:flex-nowrap flex-wrap gap-2 w-full items-center">
                        <div class="flex flex-row justify-start items-center gap-2 flex-wrap md:flex-nowrap">
                          <h3 class="font-bold whitespace-nowrap">
                            Camp Center:
                          </h3>
                          <span class="font-semibold">
                            {`${camp?.camp_location_id?.camp_location_name}`}
                          </span>
                        </div>
                        <div class="flex gap-1 items-center">
                          <span class="font-semibold">
                            <span class="text-gray-tone-divider">|</span>
                          </span>
                          <span class="font-semibold flex gap-2 items-center">
                            <LocationMap
                              width={16}
                              height={18}
                              fillColor={"#00000080"}
                            />
                            {`${camp?.camp_location_id?.address_1}, Dist - ${camp?.camp_location_id?.district?.district_name}.`}
                            <div>
                              <ViewMapLink
                                geo_coordinates={
                                  camp?.camp_location_id?.geo_coordinates
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="flex lg:flex-wrap  gap-2 w-full items-center">
                        <h3 class="font-bold whitespace-nowrap">Camp Code:</h3>
                        <div class="flex gap-1 flex-wrap items-center">
                          <span class="text-[#FF5976] font-bold">
                            {camp?.camp_code}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col justify-between gap-2 p-2 px-5 bg-white rounded-lg border-2 border-gray-100 min-w-[35%] ">
                      <div class="flex  xl:gap-5 gap-2">
                        <div class="font-semibold flex items-center justify-center gap-2">
                          <div class="font-bold h-[24px] w-[24px] bg-[#FEF2D6] rounded-[50px] flex items-center justify-center">
                            <ClockVarient width={14} height={14} />
                          </div>
                          {camp?.beneficiaryCount?.fabrication_in_progress}
                          <span class="font-semibold text-[#999999]">
                            In Progress
                          </span>
                        </div>

                        <div class="font-semibold flex items-center justify-center gap-2">
                          <div class="font-bold h-[24px] w-[24px] bg-[#EBF7FF] rounded-[50px] flex items-center justify-center">
                            <InfoIconFilled width={14} height={14} />
                          </div>
                          {camp?.beneficiaryCount?.fabrication_not_yet_started}
                          <span class="font-semibold text-[#999999]">
                            Not started yet
                          </span>
                        </div>
                      </div>
                      <div class="flex  xl:gap-5 gap-2">
                        <div class="font-semibold flex items-center justify-center gap-2">
                          <div class="font-bold h-[24px] w-[24px] bg-[#D6FFE4] rounded-[50px] flex items-center justify-center">
                            <CheckCircleGreen width={14} height={14} />
                          </div>
                          {camp?.beneficiaryCount?.fabrication_completed}
                          <span class="font-semibold text-[#999999]">
                            Completed
                          </span>
                        </div>

                        <div class="font-semibold flex items-center justify-center gap-2">
                          <div class="font-bold h-[24px] w-[24px] bg-[#FFE7E7] rounded-[50px] flex items-center justify-center">
                            <NotAllowed width={14} height={14} />
                          </div>
                          {camp?.beneficiaryCount?.not_eligible_for_fabrication}
                          <span class="font-semibold text-[#999999]">
                            Not Eligible
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-wrap gap-2 items-end text-sm">
                      <button
                        class="p-2 px-4 bg-[#FF5976] text-white rounded-lg font-semibold"
                        onClick={() => {
                          if (camp?._id) {
                            navigate(
                              `/beneficiaryListFabrication/${camp?._id}`,
                              {
                                replace: true,
                              }
                            );
                          }
                        }}
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="flex items-center justify-center h-[70%] py-0">
              <NoRecord
                title_1={"There are no records to display"}
                title_2={""}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export { FabricationListScreen };
