/**
 * Setup steps flow of Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Cross } from "../../../assets/icons/Cross";
import { FieldArray, Formik } from "formik";
import { SubtractIcon } from "../../../assets/icons/SubtractIcon";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DndDotsIcon } from "../../../assets/icons/DndDots";
import { CampFields } from "../../../constants";
import ScheduleCampDetailCardComponent from "../component/scheduleCampDetailCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampAllStages,
  getCampAllStepTypes,
  selectCampAllStages,
  selectCampAllStepTypess,
} from "../../common";
import {
  createScheduledCampStepSequence,
  getScheduleCampStepById,
  updateScheduledCampStepSequence,
} from "../slice";
import { toast } from "react-toastify";
import {
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
} from "../selector";
import SelectDropDown from "../../../components/selectDropDown";
import CustomButton from "../../../components/customButtion";
import { Delete } from "../../../assets/icons/Delete";

const SetupStepFlowModel = (props) => {
  const dispatch = useDispatch();
  const { toggleModal, rowData, getData } = props;

  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampFilter = useSelector(selectScheduleCampFilter);

  const initialValues = {
    [CampFields.SCHEDULE_CAMP_ID]: "",
    camp_flow_steps: [],
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    camp_flow_steps: Yup.array()
      .of(
        Yup.object().shape({
          camp_step_id: Yup.string().required("Step is required"),
          number_of_counters: Yup.number()
            .required("Counter is required")
            .min(1, "Counter must be greater than zero"),
        })
      )
      .required("Must have at least one step"),
  });

  const [initialObject, setInitialObject] = useState(initialValues);

  // Apis Data
  const campStepTypes = useSelector(selectCampAllStepTypess) || [];
  const stepTypeMap = campStepTypes?.reduce((map, step) => {
    map[step.camp_step_type_name] = step._id;
    return map;
  }, {});
  const campStages = useSelector(selectCampAllStages) || [];
  const campStagesOptions = campStages?.map((item) => ({
    label: item?.camp_step_display_name,
    value: item?._id,
  }));

  // beneficiary Stage Option
  const beneficiaryStageOptions =
    rowData?.camp_type_id?.beneficiary_stage_id?.map((item) => ({
      label: item?.beneficiary_stage_display_name,
      value: item?._id,
    }));

  // Functions
  const handleDragEnd = (result, values, setFieldValue) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedSteps = Array.from(values.camp_flow_steps);
    const [removed] = reorderedSteps.splice(source.index, 1);
    reorderedSteps.splice(destination.index, 0, removed);

    setFieldValue("camp_flow_steps", reorderedSteps);
  };

  const getCampStages = () => {
    dispatch(
      getCampAllStages({
        search: "",
        camp_type_id: rowData?.camp_type_id?._id,
      })
    );
  };

  const getCampStepTypes = () => {
    dispatch(
      getCampAllStepTypes({
        search: "",
      })
    );
  };

  const getScheduleCampStep = (id) => {
    dispatch(
      getScheduleCampStepById({
        id,
        onSuccess: (props) => {
          const { data } = props;
          setInitialObject({
            ...data,
            [CampFields.SCHEDULE_CAMP_ID]: data?.scheduled_camp_id?._id,
            camp_flow_steps: getActiveCampFlowSteps(data),
          });
        },
      })
    );
  };

  function getActiveCampFlowSteps(data) {
    const campFlowData =
      data?.camp_flow?.filter((step) => step.is_active) || [];
    const stepsData = campFlowData[0]?.camp_flow_steps?.map((item) => {
      return {
        beneficiary_stage_id: item?.beneficiary_stage_id?._id || null,
        camp_step_id: item?.camp_step_id?.camp_step_id?._id,
        next_step_id: item?.next_step_id?._id || null,
        step_type: item?.step_type?._id,
        number_of_counters: item?.number_of_counters,
      };
    });
    return stepsData;
  }
  useEffect(() => {
    getCampStages();
    getCampStepTypes();
    getScheduleCampStep(rowData?._id);
  }, [rowData._id]);

  return (
    <>
      <div className="fixed h-full inset-0 z-50 flex justify-end bg-[#07284b80]">
        {/* <div className="fixed inset-0 bg-[#07284B] opacity-50"></div> */}
        <div
          data-aos-easing="linear"
          data-aos="fade-left"
          className="flex flex-col relative w-[25rem] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
        >
          <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-5">
            <h2 className="text-xl font-bold">Camp Flow Setup</h2>
            <button onClick={toggleModal} className="text-xl font-semibold">
              <Cross width={32} height={32} />
            </button>
          </div>
          {/* Modal content */}
          <div className="w-full flex flex-col flex-auto justify-between space-y-4 p-3 xl:p-6">
            {/* Camp Details */}
            <div>
              <ScheduleCampDetailCardComponent data={rowData} />
            </div>
            <div className="h-full">
              <Formik
                initialValues={initialObject}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  const steps = values.camp_flow_steps?.map(
                    (step, index, array) => {
                      let stepType;

                      // Determine the step type based on index
                      if (index === 0) {
                        stepType = stepTypeMap["initial"];
                      } else if (index === array.length - 1) {
                        stepType = stepTypeMap["final"];
                      } else {
                        stepType = stepTypeMap["intermediate"];
                      }

                      // Get next step id, ensure it handles undefined cases
                      const nextStepId =
                        index + 1 < array.length
                          ? array[index + 1].camp_step_id
                          : null;

                      // Set beneficiary_stage_id based on the condition
                      let beneficiaryStageId = step.beneficiary_stage_id;

                      // If there's only one stage, set the first stage's ID
                      if (
                        rowData?.camp_type_id?.beneficiary_stage_id.length === 1
                      ) {
                        beneficiaryStageId =
                          rowData?.camp_type_id?.beneficiary_stage_id[0]?._id ||
                          null;
                      }

                      return {
                        ...step,
                        next_step_id: nextStepId,
                        step_type: stepType,
                        beneficiary_stage_id: beneficiaryStageId || null,
                      };
                    }
                  );
                  const updatedValues = {
                    ...values,
                    [CampFields.SCHEDULE_CAMP_ID]: rowData?._id,
                    camp_flow: [
                      {
                        [CampFields.SCHEDULE_CAMP_FLOW_VERSION]: 1,

                        camp_flow_steps: steps,
                      },
                    ],
                  };
                  delete updatedValues?.camp_flow_steps;

                  if (updatedValues?._id) {
                    dispatch(
                      updateScheduledCampStepSequence({
                        values: updatedValues,
                        onSuccess: (props) => {
                          toast.success(props.message);
                          getData(
                            scheduleCampSearchQuery,
                            scheduleCampFilter,
                            scheduleCampCurrentPage,
                            scheduleCampPerPage
                          );
                          toggleModal();
                        },
                      })
                    );
                  } else {
                    dispatch(
                      createScheduledCampStepSequence({
                        values: updatedValues,
                        onSuccess: (props) => {
                          toast.success(props.message);
                          getData(
                            scheduleCampSearchQuery,
                            scheduleCampFilter,
                            scheduleCampCurrentPage,
                            scheduleCampPerPage
                          );
                          toggleModal();
                        },
                      })
                    );
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => {
                  const selectedCampSteps = values?.camp_flow_steps?.map(
                    (step) => step?.camp_step_id
                  );

                  const filteredCampStagesOptions = (index) =>
                    campStagesOptions.filter(
                      (option) =>
                        !selectedCampSteps?.includes(option?.value) ||
                        option?.value ===
                          values?.camp_flow_steps[index]?.camp_step_id
                    );
                  return (
                    <form
                      className="flex flex-col h-full justify-between"
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSubmit();
                        }
                      }}
                    >
                      <div>
                        <div className="text-base font-bold mb-4">
                          Camp Flow
                        </div>
                        <div className="">
                          <div className="flex items-center gap-3 xl:gap-[1.5rem] mb-1">
                            <div className="w-[50%] text-xs xl:text-sm font-bold ps-7 xl:ps-10 flex-1">
                              Steps
                            </div>
                            <div className=" text-xs xl:text-sm font-bold flex-1">
                              No. of counters
                            </div>
                            {rowData?.camp_type_id?.beneficiary_stage_id
                              .length > 1 && (
                              <div className=" text-xs xl:text-sm font-bold flex-1">
                                Stage
                              </div>
                            )}
                          </div>

                          <DragDropContext
                            onDragEnd={(result) =>
                              handleDragEnd(result, values, setFieldValue)
                            }
                          >
                            <FieldArray
                              name="camp_flow_steps"
                              render={(arrayHelpers) => (
                                <Droppable droppableId="steps">
                                  {(provided) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {values.camp_flow_steps?.map(
                                        (row, index) => (
                                          <Draggable
                                            key={index}
                                            draggableId={`draggable-${index}`}
                                            index={index}
                                          >
                                            {(provided) => (
                                              <div
                                                className="flex items-center gap-3 xl:gap-[1.5rem] mb-2 xl:mb-5 justify-between"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                {/* Icon for dragging */}
                                                <div className="h-full">
                                                  <DndDotsIcon
                                                    width={15}
                                                    height={32}
                                                  />
                                                </div>

                                                {/* Select Dropdown for Steps */}
                                                <div className="w-[50%] flex-1">
                                                  <SelectDropDown
                                                    options={filteredCampStagesOptions(
                                                      index
                                                    )}
                                                    name={`camp_flow_steps[${index}].camp_step_id`}
                                                    placeholder="Select"
                                                    value={
                                                      campStagesOptions.find(
                                                        (item) =>
                                                          item.value ===
                                                          values
                                                            .camp_flow_steps[
                                                            index
                                                          ].camp_step_id
                                                      ) || null
                                                    }
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `camp_flow_steps[${index}].camp_step_id`,
                                                        option.value
                                                      )
                                                    }
                                                    touched={touched}
                                                    errors={errors}
                                                    isSearchable={true}
                                                    isClearable={false}
                                                  />
                                                  {touched.camp_flow_steps?.[
                                                    index
                                                  ]?.camp_step_id &&
                                                    errors.camp_flow_steps?.[
                                                      index
                                                    ]?.camp_step_id && (
                                                      <div className="mt-1 text-xs text-red-500">
                                                        {
                                                          errors
                                                            .camp_flow_steps?.[
                                                            index
                                                          ]?.camp_step_id
                                                        }
                                                      </div>
                                                    )}
                                                </div>

                                                {/* Counter Input */}
                                                <div className="flex-1">
                                                  <div className="relative flex items-center border-2 border-[#B0E0FF] rounded-xl">
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        const currentValue =
                                                          row.number_of_counters ||
                                                          0;
                                                        if (currentValue > 0) {
                                                          setFieldValue(
                                                            `camp_flow_steps[${index}].number_of_counters`,
                                                            currentValue - 1
                                                          );
                                                        }
                                                      }}
                                                      className="bg-gray-100 hover:bg-gray-200 p-3 h-10 focus:outline-none border-0 rounded-l-xl"
                                                    >
                                                      <SubtractIcon
                                                        width={12}
                                                        height={2}
                                                        iconColor={
                                                          "currentColor"
                                                        }
                                                      />
                                                    </button>
                                                    <input
                                                      type="text"
                                                      value={
                                                        values.camp_flow_steps[
                                                          index
                                                        ].number_of_counters ||
                                                        0
                                                      }
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `camp_flow_steps[${index}].number_of_counters`,
                                                          parseInt(
                                                            e.target.value
                                                          ) || 0
                                                        )
                                                      }
                                                      className="border-x-0 border-gray-300 h-10 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5"
                                                      required
                                                    />
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        const currentValue =
                                                          values
                                                            .camp_flow_steps[
                                                            index
                                                          ]
                                                            .number_of_counters ||
                                                          0;
                                                        setFieldValue(
                                                          `camp_flow_steps[${index}].number_of_counters`,
                                                          currentValue + 1
                                                        );
                                                      }}
                                                      className="bg-gray-100 hover:bg-gray-200 p-3 h-10 focus:outline-none border-0 rounded-r-xl"
                                                    >
                                                      <AddIcon
                                                        width={12}
                                                        height={12}
                                                        iconColor={
                                                          "currentColor"
                                                        }
                                                      />
                                                    </button>
                                                  </div>
                                                  {touched.camp_flow_steps?.[
                                                    index
                                                  ]?.number_of_counters &&
                                                    errors.camp_flow_steps?.[
                                                      index
                                                    ]?.number_of_counters && (
                                                      <div className="mt-1 text-xs text-red-500">
                                                        {
                                                          errors
                                                            .camp_flow_steps?.[
                                                            index
                                                          ]?.number_of_counters
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                                {/* select beneficiary stage Id */}
                                                {rowData?.camp_type_id
                                                  ?.beneficiary_stage_id
                                                  .length > 1 && (
                                                  <div className="w-[50%] flex-1">
                                                    <SelectDropDown
                                                      options={
                                                        beneficiaryStageOptions
                                                      }
                                                      name={`camp_flow_steps[${index}].beneficiary_stage_id`}
                                                      placeholder="Select"
                                                      value={
                                                        beneficiaryStageOptions?.find(
                                                          (item) =>
                                                            item?.value ===
                                                            values
                                                              .camp_flow_steps[
                                                              index
                                                            ]
                                                              .beneficiary_stage_id
                                                        ) || null
                                                      }
                                                      onChange={(option) =>
                                                        setFieldValue(
                                                          `camp_flow_steps[${index}].beneficiary_stage_id`,
                                                          option.value
                                                        )
                                                      }
                                                      touched={touched}
                                                      errors={errors}
                                                      isSearchable={true}
                                                      isClearable={false}
                                                    />
                                                  </div>
                                                )}
                                                {values.camp_flow_steps.length >
                                                  0 && (
                                                  <div
                                                    className="flex justify-start items-center cursor-pointer h-[44px]"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                  >
                                                    <Delete
                                                      width={24}
                                                      height={24}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {provided.placeholder}

                                      {/* Add more button */}
                                      <button
                                        className="text-[#33AFFF] px-4 py-2 rounded-xl bg-[#F5FBFF] ml-0 xl:ml-0 w-[calc(100%-0rem)] xl:w-[calc(100%-0px)] h-full font-medium border-[2px] border-[#B0E0FF]"
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            camp_step_id: "",
                                            number_of_counters: 0,
                                          })
                                        }
                                      >
                                        <div className="flex items-center gap-1 justify-center">
                                          Add more
                                          <AddIcon
                                            width={12}
                                            height={12}
                                            iconColor={"#33AFFF"}
                                          />
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </Droppable>
                              )}
                            />
                          </DragDropContext>
                        </div>
                      </div>

                      <div className="w-full flex justify-end space-x-4 mt-4">
                        <button
                          className="bg-[#C1C1C1] text-white px-4 text-lg py-2 font-bold rounded-lg min-w-[32%] h-full"
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                          }}
                        >
                          Reset
                        </button>
                        <CustomButton
                          name="Submit"
                          type="submit"
                          loading={false}
                          disabled={false}
                          className={""}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SetupStepFlowModel };
