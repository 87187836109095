/**
 *  Current Camp Card Component
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LiveIcon } from "../../../assets/icons/live-icon";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { User } from "../../../assets/icons/User";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import CampFlowStepComponent from "./campFlowStepCard";
import CounterCardComponent from "./counterCard";
import StepWithCountsComponent from "./stepWithNumber";
import {
  SelectActiveCampCounter,
  SelectActiveCampStep,
  SelectActiveCurrentCamp,
  SelectAllBeneficiaryListOfCamp,
  SelectAllCounterDataList,
  SelectAllCountStepDataList,
  SelectViewSingleBeneficiaryDetail,
} from "../selector";
import {
  allBeneficiaryDetailOfCampByCampId,
  countStepDataOfCurrentCamp,
  endStartCamp,
  setActiveCampCounter,
  setActiveCampStep,
  setActiveCurrentCamp,
  setLoaderBeneficiaryCamp,
} from "../slice";
import { formatDate } from "../../../utils";
import CustomButton from "../../../components/customButtion";

const CurrentCampCardComponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campFlows, setCampFlows] = useState({});
  const { campData, campIndex, getData } = props;
  const { camp, counts, users } = campData;

  const activeCurrentCamp = useSelector(SelectActiveCurrentCamp);
  const viewBeneficiaryModal = useSelector(SelectViewSingleBeneficiaryDetail);
  const activeCampStep = useSelector(SelectActiveCampStep);
  const activeCampCounter = useSelector(SelectActiveCampCounter);
  const campSteps = useSelector(SelectAllCountStepDataList);
  const campBeneficiarDetails = useSelector(SelectAllBeneficiaryListOfCamp);
  const stepCounters = useSelector(SelectAllCounterDataList);

  // State to keep track of the open step ID
  const [openStepId, setOpenStepId] = useState(null);

  const allStepsData = (id) => {
    dispatch(
      countStepDataOfCurrentCamp({
        id,
        onSuccess: () => {},
      })
    );
  };

  // Handle toggle of step
  const handleToggleStep = (stepId) => {
    setOpenStepId((prevId) => (prevId === stepId ? null : stepId)); // Toggle open/close by ID
  };

  const handleCampClick = (campData, campIndex) => {
    dispatch(
      setActiveCampCounter({
        campCounterIndex: null,
        campCounterName: "",
        campCounterId: "",
        isActiveCampCounter: false,
        searchBeneficiaryCounter: "",
      })
    );
    dispatch(
      setActiveCampStep({
        campStepIndex: null,
        campStepName: "",
        campStepId: "",
        isActiveCampStep: false,
        searchBeneficiaryStep: "",
      })
    );
    dispatch(
      setActiveCurrentCamp({
        campIndex: campIndex,
        campName: campData?.camp_location_id?.camp_location_name,
        campId: campData?._id,
        navStatus: " > Beneficiaries Overview",
        isActiveCurrentCamp: true,
        searchBeneficiaryCamp: "",
      })
    );

    // if (campData?._id) {
    //   dispatch(
    //     allBeneficiaryDetailOfCampByCampId({
    //       id: campData?._id,
    //       search: "",
    //     })
    //   );
    // }
  };

  // Initialize the Camp Flow visibility state
  useEffect(() => {
    setCampFlows((prev) => ({
      ...prev,
      [activeCurrentCamp?.campId]:
        campFlows[activeCurrentCamp?.campId] || false,
    }));

    if (activeCurrentCamp?.campId) {
      allStepsData(activeCurrentCamp?.campId);
    }

    if (activeCurrentCamp?.campId) {
      dispatch(setLoaderBeneficiaryCamp(true));
      dispatch(
        allBeneficiaryDetailOfCampByCampId({
          id: activeCurrentCamp?.campId,
          search: activeCurrentCamp?.searchBeneficiaryCamp,
          onSuccess: (props) => {
            dispatch(setLoaderBeneficiaryCamp(false));
          },
        })
      );
    }
  }, [activeCurrentCamp]);

  return (
    <>
      <div
        className="flex flex-col gap-3 items-start justify-between w-full"
        onClick={() => {
          setCampFlows((prev) => ({
            ...prev,
            [activeCurrentCamp?.campId]: !prev[activeCurrentCamp?.campId],
          }));
          handleCampClick(camp, campIndex);
        }}
      >
        <div className="flex gap-3 items-start justify-between w-full">
          <div className="flex flex-col items-start">
            <div className="flex gap-1 items-start">
              <div className="font-semibold whitespace-nowrap max-w-[140px] overflow-hidden truncate">
                {camp?.camp_location_id?.camp_location_name}
              </div>
              <div className="bg-[#00A638] rounded-md flex items-center justify-center px-[2px] py-[2px]">
                <LiveIcon width={24} height={14}></LiveIcon>
              </div>
            </div>
            <div className="flex gap-1 items-start">
              <div className="font-semibold whitespace-nowrap max-w-[140px] overflow-hidden truncate">
                {camp?.camp_type_id?.camp_type_display_name}
              </div>
            </div>
          </div>
          <div className="text-center whitespace-nowrap">
            Camp Code <br></br>
            <span className="font-bold">{camp?.camp_code}</span>
          </div>
        </div>
        {/* date n time */}
        <div className="flex justify-between gap-1 items-start w-full">
          <div className="flex justify-between gap-1 items-start">
            <CalendarNew />
            <div className="text-[#666]">{formatDate(camp?.from_date)}</div>
          </div>
          <div className="flex justify-between gap-1 items-start">
            <Clock width={18} height={18} />
            <div className="text-[#666]">
              {camp?.from_time}-{camp?.to_time}
            </div>
          </div>
        </div>
        {/* location n users */}
        <div className="flex justify-between gap-1 items-center">
          <div className="flex justify-between gap-1 items-start">
            <LocationMap width={14} height={16} fillColor={"#7B7B7B"} />

            <div className="text-[#666]">
              <p className="text-sm">
                {camp?.camp_location_id?.address_1},{" "}
                {camp?.camp_location_id?.address_2}, Dist-
                {camp?.camp_location_id?.district?.district_name}{" "}
                {camp?.camp_location_id?.state?.state_name}.
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-1 items-center">
            <User width={16} height={16} />

            <div className="font-bold text-lg">{users}</div>
          </div>
        </div>
        {/* Beneficiaries */}
        <div
          className={`flex flex-col justify-between rounded-lg w-full ${
            activeCurrentCamp?.campId === camp?._id
              ? "bg-[#DDF1FE]"
              : "bg-[#F4FBFF]"
          }`}
        >
          {/* Beneficiaries card */}
          <div className="p-3">
            <div className="flex justify-between gap-1 items-center rounded-lg w-full mb-2">
              {/* card-heading */}
              <div className="text-base font-bold">Beneficiaries</div>
              <div className="flex justify-between gap-1 items-center">
                <div
                  className={`h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center min-w-[24px] w-[24px] min-h-[24px] ${
                    campFlows[activeCurrentCamp?.campId]
                      ? "rotate-180"
                      : "rotate-90"
                  }`}
                  // onClick={() => {
                  //   setCampFlows((prev) => ({
                  //     ...prev,
                  //     [activeCurrentCamp?.campId]:
                  //       !prev[activeCurrentCamp?.campId],
                  //   }));
                  // }}
                >
                  <ArrowIcon
                    width={6}
                    height={10}
                    isRotated={campFlows[activeCurrentCamp?.campId]}
                    fillColor={"#ffffff"}
                  />
                </div>
              </div>
            </div>

            {/* list beneficiary */}
            <div className="flex flex-col justify-between gap-4 items-center rounded-lg w-full">
              <div className="flex flex-row justify-between items-center w-full">
                <StepWithCountsComponent
                  data={{ name: "Scheduled", CountNumber: counts?.scheduled }}
                  colors={{ text: "#FF5976", background: "#F5E7EE" }}
                />
                <StepWithCountsComponent
                  data={{ name: "Checked-in", CountNumber: counts?.checkedIn }}
                  colors={{ text: "#256CD0", background: "#DDF1FE" }}
                />
              </div>
              <div className="flex flex-row justify-between items-center w-full">
                <StepWithCountsComponent
                  data={{
                    name: "In Progress",
                    CountNumber: counts?.inProgress,
                  }}
                  colors={{ text: "#FAAE00", background: "#F5EFD6" }}
                />
                <StepWithCountsComponent
                  data={{
                    name: "Checked-out",
                    CountNumber: counts?.checkedOut,
                  }}
                  colors={{ text: "#04CF3D", background: "#D6F5E7" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* camp flow*/}
      {campFlows[activeCurrentCamp?.campId] && (
        <div className="p-3 border-[#DBDBDB] border-t-2 flex flex-col gap-2 bg-[#F4FBFF]">
          {/* heading */}
          <div className="text-base font-bold">Camp Flow</div>

          {/* card 1 with toggle active*/}

          {campSteps?.map((step, stepIndex) => (
            <div className="bg-[white] border-2 border-[#61B1E5] overflow-hidden rounded-xl space-y-3 w-full">
              {/* heading with toggle btn */}
              <div
                key={step?._id}
                // onClick={() => handleStepClick(step, stepIndex)}
              >
                <CampFlowStepComponent
                  stepData={step}
                  stepIndex={stepIndex}
                  activeCampId={camp?._id}
                  isViewCounter={
                    openStepId === step?.stepCount?.stepDetails?._id
                  }
                  setIsViewCounter={() =>
                    handleToggleStep(step?.stepCount?.stepDetails?._id)
                  }
                />
              </div>
              {/* toggle-body */}
              {openStepId === step?.stepCount?.stepDetails?._id && (
                <div className="space-y-2">
                  {step?.beneficiaryCountDetails?.counterDetails?.map(
                    (counter, counterIndex) => {
                      return (
                        <>
                          <div key={counterIndex}>
                            <CounterCardComponent
                              counterData={counter}
                              counterIndex={counterIndex}
                              activeCampId={camp?._id}
                              activeCampStepId={
                                step?.stepCount?.stepDetails?._id
                              }
                            />
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          ))}

          {/* card 2 with toggle*/}
        </div>
      )}
      <div className="flex justify-end w-full mt-4">
        {" "}
        <CustomButton
          name="End Camp"
          type="button"
          loading={false}
          disabled={false}
          onClick={() => {
            dispatch(
              endStartCamp({
                campId: camp?._id,
                onSuccess: () => {
                  getData({ search: "" });
                },
              })
            );
          }}
          className={"text-white mt-0"}
        />
      </div>
    </>
  );
};

export default CurrentCampCardComponent;
