/**
 * Single Date Picker Component
 * @format
 */

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SingleDatePickerComponent = (props) => {
  const {
    name,
    value,
    onChange,
    placeholderText = "Select Date Range",
    isClearable = true,
    dateFormat = "dd-MMM-yyyy",
    className = "w-full h-[44px] rounded-xl text-sm border-[2px] px-2 border-customBlue-border placeholder-black",
    minDate,
  } = props;
  const parseDate = (dateString) => {
    if (!dateString) return null;

    const [year, month, day] = dateString.split("-");
    return new Date(year, month - 1, day);
  };

  return (
    <DatePicker
      name={name}
      selected={parseDate(value)}
      onChange={onChange}
      placeholderText={placeholderText}
      className={className}
      isClearable={isClearable}
      dateFormat={dateFormat}
      minDate={minDate}
    />
  );
};

export default SingleDatePickerComponent;
